import React from "react"
import styles from "./PeerView.module.scss"
import {Peer} from "@damntools.fr/wnab-data"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {
  confirmPeerDeletion,
  HiddenToggle,
  openPeerViewPopup
} from "../../component"
import {png_delete2, png_menu_edit} from "../../assets"
import {PeerApiService} from "../../service"
import {PeerProvider} from "../../provider"
import {Strings, tsl} from "../../i18n"
import {getPeerTypeIcon} from "../../utils"

export type PeerEntryProps = {
  peer: Peer
}

export class PeerEntry extends React.Component<PeerEntryProps, any> {
  render() {
    const peer = this.props.peer
    const icon = getPeerTypeIcon(this.props.peer.type)
    const typeTitle =
      Strings.resources.peer.types[
        this.props.peer.type.key() as "entity" | "person"
      ]
    return (
      <div className={styles.PeerEntry}>
        <div>
          <div className={styles.Icon}>
            <span></span>
            <img
              src={icon}
              alt={this.props.peer.type.key()}
              title={tsl(typeTitle)}
            />
          </div>
          <div className={styles.EntryTitle}>
            <span>{peer.name}</span>
          </div>
          <div
            className={styles.Hidden}
            title={tsl(
              peer.hidden
                ? Strings.views.peer.hidden
                : Strings.views.peer.notHidden
            )}>
            <HiddenToggle
              hidden={peer.hidden}
              onChange={v => this.onChangeHidden(v)}
            />
          </div>
          <div className={styles.Edit}>
            <div onClick={() => openPeerViewPopup(peer)}>
              <span></span>
              <img
                src={png_menu_edit}
                alt={"edit"}
                title={tsl(Strings.views.peer.edit)}
              />
            </div>
          </div>
          <div className={styles.Delete}>
            <div onClick={() => confirmPeerDeletion(peer)}>
              <span></span>
              <img
                src={png_delete2}
                alt={"delete"}
                title={tsl(Strings.views.peer.delete)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  onChangeHidden(value: boolean) {
    PeerApiService.get()
      .update({id: this.props.peer.id, hidden: value})
      .then(() => PeerProvider.refresh())
      .catch(err => {
        console.error("err", err)
        AlertProvider.submit(
          Notification.error(
            tsl(Strings.views.peer.hiddenErrorMessage)
          ).Subtitle(err?.response?.data?.reason || "")
        )
      })
  }
}
