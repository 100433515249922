import {ContextMenu} from "@damntools.fr/react-layout"
import {RichTransaction} from "@damntools.fr/wnab-data"
import styles from "./ContextMenu.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {Strings, tsl} from "../../../i18n"
import {
  confirmTxClear,
  confirmTxDeletion,
  confirmTxDuplication,
  openTransactionEditPopup
} from "../../popin"
import {Lists, Optional} from "@damntools.fr/types"
import {TransactionTableProviderState} from "../../../provider"

export const TransactionTableContextMenu =
  (provider: TransactionTableProviderState, showAccount: boolean | undefined) =>
  (r: RichTransaction) => {
    const cm = ContextMenu.right("hey")
      .menuClass(CssClass.from(styles.Menu))
      .overlayClass(CssClass.from(styles.Overlay))
      .itemClass(CssClass.from(styles.Item))
      .separatorClass(CssClass.from(styles.Separator))
      .addAction({
        onClick: () =>
          openTransactionEditPopup(Optional.of(r), Optional.of(r.account)),
        display: tsl(Strings.transactionTable.toolbar.edit)
      })
      .addAction({
        onClick: () => confirmTxDeletion(Lists.of(r)),
        display: tsl(Strings.transactionTable.toolbar.delete)
      })
      .addAction({
        onClick: () => confirmTxClear(Lists.of(r)),
        display: tsl(Strings.transactionTable.toolbar.clear)
      })
      .addAction({
        onClick: () => confirmTxDuplication(Lists.of(r)),
        display: tsl(Strings.transactionTable.toolbar.duplicate)
      })
      .addSeparator()
      .addAction({
        onClick: () =>
          provider.setFullSearch("peer", Optional.fromString(r.peer?.name)),
        display: tsl(Strings.transactionTable.contextMenu.filterByPeer)
      })
      .addAction({
        onClick: () =>
          provider.setFullSearch(
            "category",
            Optional.fromString(r.category?.pretty())
          ),
        display: tsl(Strings.transactionTable.contextMenu.filterByCategory)
      })
      .addAction({
        onClick: () =>
          provider.setFullSearch(
            "description",
            Optional.fromString(r.description)
          ),
        display: tsl(Strings.transactionTable.contextMenu.filterByDescription)
      })
    if (showAccount) {
      cm.addAction({
        onClick: () =>
          provider.setFullSearch(
            "account",
            Optional.fromString(r.account.name)
          ),
        display: tsl(Strings.transactionTable.contextMenu.filterByAccount)
      })
    }

    return cm
  }
