import React from "react"
import {ValueDesc, VD} from "@damntools.fr/react-inputs"
import {
  ArrayList,
  List,
  Optionable,
  Optional,
  toList
} from "@damntools.fr/types"
import {Category} from "@damntools.fr/wnab-data"
import {CategoryApiService} from "../../../service"
import {
  CategoryConsumer,
  CategoryProvider,
  TransactionProvider
} from "../../../provider"
import {PopinForm} from "../PopinForm"
import {Check, Validator} from "@damntools.fr/validators"
import {
  MoveCategoryTransactionsViewProps,
  MoveCategoryTransactionsViewState
} from "./MoveCategoryTransactionsView.props"
import {Strings, tsl} from "../../../i18n"
import styles from "./MoveCategoryTransactionsView.module.scss"
import {
  FlexRowBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"

export const openMoveCategoryTransactionPopup = (
  source?: Category,
  target?: Category
) =>
  PopinForm.open<
    MoveCategoryTransactionsViewProps,
    MoveCategoryTransactionsView
  >(
    {
      source,
      target,
      title: tsl(Strings.popins.moveCategoryTransactions.title)
    },
    MoveCategoryTransactionsView
  )

export class MoveCategoryTransactionsView extends PopinForm<
  MoveCategoryTransactionsViewProps,
  MoveCategoryTransactionsViewState,
  MoveCategoryTransactionsViewState
> {
  constructor(props: MoveCategoryTransactionsViewProps) {
    super(props, "MoveCategoryTransactionsView")
  }

  render() {
    return (
      <CategoryConsumer>
        {({subCategories}) => {
          const values = this.getCategoryValues(subCategories)
          const selectedSource = this.getSelectedSource(values)
          const selectedTarget = this.getSelectedTarget(values)
          return (
            <div className={styles.Form}>
              <div>
                <div className={styles.Description}>
                  <p>
                    {tsl(Strings.popins.moveCategoryTransactions.description)}
                  </p>
                </div>
                <FlexRowBlock className={styles.Columns}>
                  <GrowShrinkFlexCell>
                    <div className={styles.RowLabel}>
                      {tsl(
                        Strings.popins.moveCategoryTransactions.fields.source
                      )}
                    </div>
                    <div>
                      {this.getDropdown(
                        v => this.onChangeOptional("source", v),
                        values,
                        selectedSource
                          .map(v => new ArrayList([v]))
                          .orElseReturn(new ArrayList<ValueDesc<Category>>()),
                        undefined,
                        "150px"
                      )}
                    </div>
                  </GrowShrinkFlexCell>
                  <ShrinkFlexCell className={styles.Middle}>
                    <span></span>
                    <div>{">"}</div>
                  </ShrinkFlexCell>
                  <GrowShrinkFlexCell>
                    <div className={styles.RowLabel}>
                      {tsl(
                        Strings.popins.moveCategoryTransactions.fields.target
                      )}
                    </div>
                    <div>
                      {this.getDropdown(
                        v => this.onChangeOptional("target", v),
                        values,
                        selectedTarget
                          .map(v => new ArrayList([v]))
                          .orElseReturn(new ArrayList<ValueDesc<Category>>()),
                        undefined,
                        "150px"
                      )}
                    </div>
                  </GrowShrinkFlexCell>
                </FlexRowBlock>
              </div>
            </div>
          )
        }}
      </CategoryConsumer>
    )
  }

  protected getForm(): React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > {
    return <div></div>
  }

  protected getInitialState(): MoveCategoryTransactionsViewState {
    return {
      source: Optional.nullable(this.props.source),
      target: Optional.nullable(this.props.target)
    }
  }

  protected getValidator(): Validator<MoveCategoryTransactionsViewState> {
    return new Validator<MoveCategoryTransactionsViewState>()
      .addCheck(
        Check.field("source").with(
          Check.optional().provided(
            tsl(Strings.popins.moveCategoryTransactions.error.emptySource)
          )
        )
      )
      .addCheck(
        Check.field("target").with(
          Check.optional().provided(
            tsl(Strings.popins.moveCategoryTransactions.error.emptyTarget)
          )
        )
      )
  }

  private getCategoryValues(
    categories: List<Category>
  ): List<ValueDesc<Category>> {
    return categories
      .copy()
      .stream()
      .map(p => VD(p).Compare(p.id).Display(p.pretty()).Sort(p.pretty()))
      .collect(toList)
  }

  private getSelectedSource(
    values: List<ValueDesc<Category>>
  ): Optionable<ValueDesc<Category>> {
    return values
      .stream()
      .findOptional(v =>
        this.state.source.filter(s => s.id === v.returnValue.id).isPresent()
      )
  }

  private getSelectedTarget(
    values: List<ValueDesc<Category>>
  ): Optionable<ValueDesc<Category>> {
    return values
      .stream()
      .findOptional(v =>
        this.state.target.filter(s => s.id === v.returnValue.id).isPresent()
      )
  }

  protected onSuccess(model: MoveCategoryTransactionsViewState) {
    return CategoryApiService.get()
      .moveTransactions(model.source.get(), model.target.get())
      .then(count =>
        this.processSuccess(
          tsl(Strings.popins.moveCategoryTransactions.successMessage).replace(
            "{{count}}",
            count + ""
          )
        )
      )
      .then(() => CategoryProvider.refresh())
      .then(() => TransactionProvider.refresh())
      .catch(err =>
        this.processError(
          err,
          tsl(Strings.popins.moveCategoryTransactions.error.updateError)
        )
      )
  }
}
