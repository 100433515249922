import {ArrayList, List, toList} from "@damntools.fr/types"
import {
  BudgetDto,
  BudgetDtoMapper,
  BudgetSheet,
  BudgetSheetDto,
  BudgetSheetDtoMapper,
  Category,
  CreateBudgetDto,
  RichBudget,
  RichTransaction
} from "@damntools.fr/wnab-data"
import {AxiosWrapper} from "@damntools.fr/http"
import {AxiosService} from "../auth"
import {DateTime} from "luxon"

export class BudgetApiService {
  static INSTANCE: BudgetApiService | null = null
  private readonly axios: AxiosWrapper

  constructor() {
    this.axios = AxiosService.getAuthenticatedInstance().child({
      baseURL: "/resource/budget"
    })
  }

  getBudgets(categories: List<Category>): Promise<List<RichBudget>> {
    return this.axios
      .get("/")
      .then(res => new ArrayList<BudgetDto>(res.data))
      .then(res =>
        res
          .stream()
          .map(a => BudgetDtoMapper.get().mapTo(a))
          .map(
            a =>
              new RichBudget({
                ...a,
                category: categories
                  .stream()
                  .findFirst(c => c.id === a.id)
                  .orElseUndefined() as Category
              })
          )
          .collect(toList)
      )
  }

  getBudgetSheetForMonth(
    month: DateTime,
    transactions: List<RichTransaction>,
    categories: List<Category>
  ): Promise<BudgetSheet> {
    return this.axios
      .get<BudgetSheetDto>(`/sheet/${month.toFormat("yyyyMM")}`)
      .then(res => res.data as BudgetSheetDto)
      .then(res =>
        BudgetSheetDtoMapper.get().mapTo(res, transactions, categories)
      )
  }

  moveBudgetFunds(
    month: DateTime,
    sourceCategoryId: number,
    targetCategoryId: number,
    amount: number
  ) {
    const body = {
      entries: [
        {
          amount: amount,
          sourceCategoryId,
          targetCategoryId
        }
      ]
    }
    return this.axios
      .put(`/month/${month.toFormat("yyyyMM")}/move`, body)
      .then(() => true)
  }

  createBudgetForMonthAndCategory(month: DateTime, category: Category) {
    return this.axios
      .post(`/month/${month.toFormat("yyyyMM")}/category/${category.id}`)
      .then(() => true)
  }

  create(budget: CreateBudgetDto) {
    return this.axios
      .post("/", budget)
      .then(res => res.data as BudgetDto)
      .then(res => BudgetDtoMapper.get().mapTo(res))
  }

  update(budget: RichBudget) {
    if (!budget.id) return Promise.reject("Budget should contains id ! ")
    const dto = {
      activity: budget.activity,
      available: budget.available,
      budgeted: budget.budgeted
    }
    return this.axios.put(`/${budget.id}`, dto)
  }

  updateBudgeted(budgets: List<RichBudget>, value: number) {
    const ids = budgets
      .stream()
      .map(b => b.id)
      .collectArray()
    if (ids.length > 0)
      return this.axios.put(
        `/budgeted?value=${encodeURIComponent(value.toString())}&ids=${ids.join(",")}`,
        {}
      )
    return Promise.resolve()
  }

  delete(ids: List<number>) {
    return this.axios.delete(`?ids=${ids.stream().join(",")}`).then(() => {})
  }

  static get(): BudgetApiService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new BudgetApiService()
    }
    return this.INSTANCE
  }
}
