import React from "react"
import styles from "./FlagView.module.scss"
import {FlagViewEntry} from "./FlagViewEntry"
import {RichTransaction, TransactionFlag} from "@damntools.fr/wnab-data"
import {List} from "@damntools.fr/types"
import {TransactionConsumer} from "../../provider"
import {openTxFlagViewPopup} from "../../component"
import {png_add_account} from "../../assets"
import {Strings, tsl} from "../../i18n"
import {
  FlexColumnBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {BubbleLink, RGBA} from "@damntools.fr/react-utils"

export class FlagView extends React.Component<any, any> {
  componentDidMount() {
    document.title = tsl(Strings.views.flag.title)
  }

  render() {
    return (
      <TransactionConsumer>
        {({flags, transactions}) => {
          return (
            <div className={styles.FlagView}>
              <FlexColumnBlock>
                <ShrinkFlexCell className={styles.Title}>
                  <h1>{tsl(Strings.views.flag.title)}</h1>
                </ShrinkFlexCell>
                <ShrinkFlexCell className={styles.Toolbar}>
                  <div className={styles.Add}>
                    <BubbleLink
                      title={tsl(Strings.views.flag.add)}
                      icon={png_add_account}
                      bgColor={RGBA(144, 180, 33, 0.5)}
                      effect={"brightness"}
                      onClick={() => openTxFlagViewPopup()}
                    />
                  </div>
                </ShrinkFlexCell>
                <GrowShrinkFlexCell className={styles.List} hideOverflow>
                  <div>
                    <div>
                      {flags
                        .copy()
                        .sortWith("name")
                        .stream()
                        .map(f => (
                          <FlagViewEntry
                            transactionCount={this.getTransactionCount(
                              f,
                              transactions
                            )}
                            flag={f}
                            key={f.id}
                          />
                        ))
                        .collectArray()}
                    </div>
                  </div>
                </GrowShrinkFlexCell>
              </FlexColumnBlock>
            </div>
          )
        }}
      </TransactionConsumer>
    )
  }

  private getTransactionCount(
    flag: TransactionFlag,
    transactions: List<RichTransaction>
  ) {
    return transactions
      .stream()
      .filter(tx => !!tx.flag && tx.flag.id === flag.id)
      .count()
  }
}
