import styles from "./ReportPage.module.scss"
import {ReportPageConsumer, ReportPageViewType} from "../../provider"
import {
  png_chart_candle,
  png_chart_line,
  png_chart_pie,
  png_menu_chart
} from "../../assets"
import {CssClass, StringUtils} from "@damntools.fr/utils-simple"
import React from "react"

type ReportPageHeaderProps = {
  onClickLink: (s: string) => void
}

export function ReportPageHeader({onClickLink}: ReportPageHeaderProps) {
  return (
    <div className={styles.Header}>
      <h1>{"Reports"}</h1>
      <ReportPageConsumer>
        {({viewType}) =>
          getViewTypeLinks(viewType, (type: ReportPageViewType) =>
            onClickLink(`/report/${type}`)
          )
        }
      </ReportPageConsumer>
    </div>
  )
}

function getViewTypeLinks(
  viewType: ReportPageViewType,
  onClickLink: (type: ReportPageViewType) => any
) {
  return (
    <div className={styles.ViewTypeLinks}>
      <ReportLink
        viewType={"overview"}
        active={viewType}
        icon={png_menu_chart}
        onClick={onClickLink}
      />
      <ReportLink
        viewType={"pie"}
        active={viewType}
        icon={png_chart_pie}
        onClick={onClickLink}
      />
      <ReportLink
        viewType={"line"}
        active={viewType}
        icon={png_chart_line}
        onClick={onClickLink}
      />
      <ReportLink
        viewType={"balance"}
        active={viewType}
        icon={png_chart_line}
        onClick={onClickLink}
      />
      <ReportLink
        viewType={"candle"}
        active={viewType}
        icon={png_chart_candle}
        onClick={onClickLink}
      />
    </div>
  )
}

type ReportLinkProps = {
  viewType: ReportPageViewType
  active: ReportPageViewType
  icon: string
  onClick: (type: ReportPageViewType) => void
}

function ReportLink({viewType, active, icon, onClick}: ReportLinkProps) {
  return (
    <div
      className={CssClass.from(styles.Tile)
        .classIf(styles.SelectedView, viewType === active)
        .get()}
      onClick={() => onClick(viewType)}>
      <div>
        <div>
          <span>{StringUtils.firstToUpper(viewType)}</span>
        </div>
        <div>
          <img src={icon} alt={viewType} />
        </div>
      </div>
    </div>
  )
}
