import React from "react"
import {BudgetApiService} from "../../../service"
import {Optionable, Optional} from "@damntools.fr/types"
import {BudgetEntry, Category} from "@damntools.fr/wnab-data"
import {BudgetAssignFormAvailable} from "./BudgetAssignFormAvailable"
import {DateTime} from "luxon"
import {
  AssignFormParam,
  BudgetProvider,
  BudgetViewConsumer,
  CategoryConsumer,
  FormType
} from "../../../provider"

export type BudgetAssignFormProps = {
  month: DateTime
}
export type BudgetAssignFormState = {}

export class BudgetAssignForm extends React.Component<
  BudgetAssignFormProps,
  BudgetAssignFormState
> {
  constructor(props: Readonly<BudgetAssignFormProps> | BudgetAssignFormProps) {
    super(props)
    this.state = {
      amount: Optional.empty(),
      category: Optional.empty()
    }
  }

  render() {
    return (
      <CategoryConsumer>
        {({subCategories}) => (
          <BudgetViewConsumer>
            {({assignFormParam, assignForm}) => {
              if (assignFormParam.isEmpty()) return null
              const param = assignFormParam.get()
              return param.type === "available" ? (
                <BudgetAssignFormAvailable
                  text={`Assign available from ${param.budget.budget.category?.name} to`}
                  categories={subCategories}
                  sourceCategory={param.budget.budget.category as Category}
                  initialAmount={param.budget.budget.available}
                  onValidate={(
                    source: Category,
                    target: Category,
                    amount: number
                  ) =>
                    this.validateAvailable(
                      source,
                      target,
                      amount,
                      assignForm,
                      assignFormParam
                    )
                  }
                />
              ) : (
                <BudgetAssignFormAvailable
                  text={`Assign ${param.budget.budget.category?.name} budget from`}
                  categories={subCategories}
                  sourceCategory={param.budget.budget.category as Category}
                  initialAmount={
                    param.budget.budget.activity - param.budget.budget.budgeted
                  }
                  onValidate={(
                    source: Category,
                    target: Category,
                    amount: number
                  ) =>
                    this.validateAssignFrom(
                      source,
                      target,
                      amount,
                      assignForm,
                      assignFormParam
                    )
                  }
                />
              )
            }}
          </BudgetViewConsumer>
        )}
      </CategoryConsumer>
    )
  }

  private validateAvailable(
    source: Category,
    target: Category,
    amount: number,
    assignForm: (budget: BudgetEntry, type: FormType) => void,
    assignFormParam: Optionable<AssignFormParam>
  ) {
    BudgetApiService.get()
      .moveBudgetFunds(
        this.props.month,
        source.id as number,
        target.id as number,
        amount
      )
      .then(() => BudgetProvider.refresh())
      .then(() =>
        assignFormParam.ifPresentDo(p => assignForm(p.budget, p.type))
      )
  }

  private validateAssignFrom(
    source: Category,
    target: Category,
    amount: number,
    assignForm: (budget: BudgetEntry, type: FormType) => void,
    assignFormParam: Optionable<AssignFormParam>
  ) {
    BudgetApiService.get()
      .moveBudgetFunds(
        this.props.month,
        target.id as number,
        source.id as number,
        amount
      )
      .then(() => BudgetProvider.refresh())
      .then(() =>
        assignFormParam.ifPresentDo(p => assignForm(p.budget, p.type))
      )
  }
}
