import {Dict, KV} from "@damntools.fr/types"
import {BaseStrings} from "./base"

export class I18N {
  private static INSTANCE: I18N

  private readonly strings: Dict<string, BaseStrings> = KV.empty()

  private constructor() {}

  get(key: keyof BaseStrings): keyof BaseStrings {
    return key
  }

  register(language: string, strings: BaseStrings) {
    this.strings.put(language, strings)
    return this
  }

  prepare() {
    return Object.fromEntries(
      this.strings
        .entries()
        .stream()
        .map(e => [e.key, {translation: e.value}])
        .collectArray()
    )
  }

  static get() {
    if (!this.INSTANCE) this.INSTANCE = new I18N()
    return this.INSTANCE
  }

  prepareStrings(): BaseStrings {
    return this.strings
      .entries()
      .first()
      .map(e => {
        return this.getFlatStringsFromObject(e.value)
      })
      .get() as any
  }

  private getFlatStringsFromObject(obj: any, root?: string): object {
    let res: any = {}
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === "object") {
        res[key] = this.getFlatStringsFromObject(
          obj[key],
          root ? root + "." + key : key
        )
      } else {
        res[key] = root ? root + "." + key : key
      }
    })
    return res
  }
}
