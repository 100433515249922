import {AuthenticatedWrapper, AxiosWrapper} from "@damntools.fr/http"
import {Globals} from "@damntools.fr/react-globals"
import {LocalStorageManager} from "./CustomLocalStorageManager"

export class AxiosService {
  static INSTANCE: AxiosService | null = null
  private readonly baseInstance: AxiosWrapper
  private readonly authenticatedInstance: AuthenticatedWrapper

  constructor() {
    this.baseInstance = new AxiosWrapper({
      baseURL: AxiosService.getBackendUrl()
    })
    this.authenticatedInstance = AuthenticatedWrapper.fromWrapper(
      this.baseInstance,
      LocalStorageManager
    )
    this.authenticatedInstance.setTokenExpiredHandler(() => {
      debugger
      LocalStorageManager.removeAuthentication().then(() => {
        debugger
        window.location.reload()
      })
    })
  }

  static getBackendUrl(): string {
    Globals.getAll().log()
    return Globals.get<string>("backend.url").orElseThrow(
      () => new Error("Backend url should be provided as global !")
    )
  }

  static getInstance(): AxiosWrapper {
    return this.get().baseInstance
  }

  static getAuthenticatedInstance(): AuthenticatedWrapper {
    return this.get().authenticatedInstance
  }

  private static get(): AxiosService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new AxiosService()
    }
    return this.INSTANCE
  }
}
