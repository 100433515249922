import {Category, Peer, TransactionFlag} from "@damntools.fr/wnab-data"
import {AlertProvider, Notification, Popin} from "@damntools.fr/react-alert"
import {
  CategoryApiService,
  PeerApiService,
  TransactionFlagApiService
} from "../../service"
import {Lists} from "@damntools.fr/types"
import {Strings, tsl} from "../../i18n"
import {
  CategoryProvider,
  PeerProvider,
  TransactionProvider
} from "../../provider"
import {StringUtils} from "@damntools.fr/utils-simple"
import React from "react"

function processError(type: string, err: Error) {
  const title = tsl(Strings.popins.deletion.errorMessage).replace(
    "{{type}}",
    type.toLowerCase()
  )
  console.error(title, err)
  const error = err as any
  return AlertProvider.submit(
    Notification.error(title).Subtitle(error?.response?.data?.reason || "")
  )
}

function getConfirmMessage(type: string) {
  return tsl(Strings.popins.deletion.confirmMessage).replace(
    "{{type}}",
    type.toLowerCase()
  )
}

function getSuccessMessage(type: string) {
  return tsl(Strings.popins.deletion.successMessage).replace(
    "{{type}}",
    StringUtils.firstToUpper(type)
  )
}

export const confirmCategoryDeletion = (category: Category) => {
  const type = tsl(Strings.resources.category.single)
  return AlertProvider.submit(
    Popin.warning(getConfirmMessage(type))
      .Subtitle(category.name)
      .Content(
        <div>
          <p>{tsl(Strings.popins.deletion.category.fullMessage)}</p>
          {!category.parent && (
            <p>{tsl(Strings.popins.deletion.category.parentMessage)}</p>
          )}
        </div>
      )
      .OnSuccess(() => {
        CategoryApiService.get()
          .delete(Lists.of(category.id))
          .then(() =>
            AlertProvider.submit(Notification.success(getSuccessMessage(type)))
          )
          .then(() => CategoryProvider.refresh())
          .then(() => TransactionProvider.refresh())
          .catch(err => processError(type, err))
      })
  )
}

export const confirmPeerDeletion = (peer: Peer) => {
  const type = tsl(Strings.resources.peer.single)
  return AlertProvider.submit(
    Popin.warning(getConfirmMessage(type))
      .Subtitle(peer.name)
      .Content(
        <div>
          <p>{tsl(Strings.popins.deletion.peer.fullMessage)}</p>
        </div>
      )
      .OnSuccess(() => {
        PeerApiService.get()
          .delete(Lists.of(peer.id))
          .then(() =>
            AlertProvider.submit(Notification.success(getSuccessMessage(type)))
          )
          .then(() => PeerProvider.refresh())
          .then(() => TransactionProvider.refresh())
          .catch(err => processError(type, err))
      })
  )
}

export const confirmFlagDeletion = (flag: TransactionFlag) => {
  const type = tsl(Strings.resources.flag.single)
  return AlertProvider.submit(
    Popin.warning(getConfirmMessage(type))
      .Subtitle(flag.name)
      .Content(
        <div>
          <p>{tsl(Strings.popins.deletion.flag.fullMessage)}</p>
        </div>
      )
      .OnSuccess(() => {
        TransactionFlagApiService.get()
          .delete(Lists.of(flag.id))
          .then(() =>
            AlertProvider.submit(Notification.success(getSuccessMessage(type)))
          )
          .then(() => TransactionProvider.refresh())
          .catch(err => processError(type, err))
      })
  )
}
