import React from "react"
import styles from "./ReportPage.module.scss"
import {BalanceReportView, OverviewReportView} from "./report"
import {useNavigate} from "react-router-dom"
import {useParams} from "react-router"
import {
  ReportPageConsumer,
  ReportPageProvider,
  ReportPageViewType,
  TransactionConsumer
} from "../../provider"
import {Lists} from "@damntools.fr/types"
import {TransactionFilter} from "../../service"
import {ReportPageFilters} from "./ReportPageFilters"
import {ReportPageOptions} from "./ReportPageOptions"
import {ReportPageHeader} from "./ReportPageHeader"

export function ReportPage() {
  const {type} = useParams()
  const nav = useNavigate()
  const viewType = type as ReportPageViewType
  return (
    <ReportPageProvider urlViewType={viewType}>
      <div className={styles.ReportPage}>
        <div>
          <ReportPageHeader onClickLink={nav} />
          <ReportPageFilters />
          <ReportPageOptions />
          {getContent()}
        </div>
      </div>
    </ReportPageProvider>
  )
}

function getContent() {
  return (
    <ReportPageConsumer>
      {({viewType, timeFilter}) => (
        <TransactionConsumer>
          {({transactions}) => {
            const timeFilteredTransactions = timeFilter.isPresent()
              ? TransactionFilter.filterByTimeFilter(
                  transactions,
                  timeFilter.get()
                )
              : transactions
            return (
              <div className={styles.Content}>
                <div>
                  {viewType === "balance" ? <BalanceReportView /> : null}
                  {viewType === "overview" ? (
                    <OverviewReportView
                      timeFilteredTransactions={timeFilteredTransactions}
                      categories={Lists.empty()}
                      accounts={Lists.empty()}
                      flags={Lists.empty()}
                      peers={Lists.empty()}
                    />
                  ) : null}
                </div>
              </div>
            )
          }}
        </TransactionConsumer>
      )}
    </ReportPageConsumer>
  )
}
