import styles from "./MultiLinkBubble.module.scss"
import {
  ColorUtils,
  MaskedImage,
  RgbValue,
  StyleSize
} from "@damntools.fr/react-utils"
import React, {CSSProperties} from "react"
import {CssClass} from "@damntools.fr/utils-simple"
import {abstractArrayToArray, List} from "@damntools.fr/types"

export type MultiLinkBubbleLinkProps = {
  title: string
  icon?: string
  iconSize?: StyleSize
  onlyDisplayIcon?: boolean
  onClick: () => void
  active?: boolean
}

export type MultiLinkBubbleProps = {
  icon: string
  title: string
  showTitle?: boolean
  iconSize?: StyleSize
  bgColor?: RgbValue
  effect?: "brightness" | "opacity"
  className?: CssClass | string
  color?: RgbValue
  links: Array<MultiLinkBubbleLinkProps> | List<MultiLinkBubbleLinkProps>
}

export function MultiLinkBubble({
  icon,
  iconSize,
  title,
  color,
  links,
  className,
  bgColor
}: MultiLinkBubbleProps) {
  const classes = CssClass.ofAny(className).with(styles.MultiLinkBubble)
  const style: CSSProperties = {}
  if (color) style.color = ColorUtils.rgbToStyle(color)
  if (bgColor) style.backgroundColor = ColorUtils.rgbToStyle(bgColor)
  return (
    <div className={classes.get()} style={style}>
      <span></span>
      <MaskedImage
        className={styles.Masked}
        color={color || ColorUtils.colorToRGBA("white")}
        icon={icon}
        title={title}
        width={(iconSize && iconSize.toCss()) || "unset"}
      />
      <span className={styles.Separator}>|</span>
      {abstractArrayToArray(links).map(l => (
        <span
          className={CssClass.from(styles.Button)
            .classIf(styles.Active, l.active)
            .get()}
          onClick={() => l.onClick()}>
          {l.icon ? (
            <span>
              <span></span>
              <MaskedImage
                icon={l.icon}
                title={l.title}
                width={
                  (l.iconSize && l.iconSize.toCss()) ||
                  (iconSize && iconSize.toCss()) ||
                  "unset"
                }
                color={color || ColorUtils.colorToRGBA("white")}
              />
            </span>
          ) : null}
          {!l.onlyDisplayIcon ? <span>{l.title}</span> : null}
        </span>
      ))}
    </div>
  )
}
