import {ArrayList, Dict, KV, List, toList} from "@damntools.fr/types"
import {RichTransaction} from "@damntools.fr/wnab-data"

export type ChartDataEntry = {
  name: string
  value: number
  isPositive?: boolean
  isNegative?: boolean
  label?: string
  subLabel?: string
}

export type CompleteChartDataEntry = ChartDataEntry & {
  percent: number
}

export type GroupEntry<T> = {
  label?: string
  subLabel?: string
  value: T
}

export class ReportUtils {
  static groupTransactionsByMonths(transactions: List<RichTransaction>) {
    const groups = KV.empty<number, List<RichTransaction>>()
    transactions.forEach(tx => {
      const date = tx.date
        .set({day: 1, hour: 0, minute: 0, second: 0, millisecond: 0})
        .toMillis()
      if (groups.hasKey(date)) {
        groups.get(date).push(tx)
      } else {
        groups.put(date, new ArrayList([tx]))
      }
    })
    return groups
  }

  static groupTransactionsByDays(transactions: List<RichTransaction>) {
    const groups = KV.empty<number, List<RichTransaction>>()
    transactions.forEach(tx => {
      const date = tx.date
        .set({hour: 0, minute: 0, second: 0, millisecond: 0})
        .toMillis()
      if (groups.hasKey(date)) {
        groups.get(date).push(tx)
      } else {
        groups.put(date, new ArrayList([tx]))
      }
    })
    return groups
  }

  static groupTransactionsByCategories(
    transactions: List<RichTransaction>
  ): Dict<string, List<RichTransaction>> {
    const groups = KV.empty<string, List<RichTransaction>>()
    transactions.forEach(tx => {
      const categoryId = tx.category?.name
      if (categoryId) {
        if (groups.hasKey(categoryId)) {
          groups.get(categoryId).push(tx)
        } else {
          groups.put(categoryId, new ArrayList([tx]))
        }
      }
    })
    return groups
  }

  static groupTransactionsByParentCategories(
    transactions: List<RichTransaction>
  ): Dict<string, GroupEntry<List<RichTransaction>>> {
    const groups = KV.empty<string, GroupEntry<List<RichTransaction>>>()
    transactions.forEach(tx => {
      const categoryId = tx.category?.parent?.name
      if (categoryId) {
        if (groups.hasKey(categoryId)) {
          groups.get(categoryId).value.push(tx)
        } else {
          groups.put(categoryId, {
            label: `${categoryId}`,
            subLabel: `${categoryId}`,
            value: new ArrayList([tx])
          })
        }
      }
    })
    return groups
  }

  static groupTransactionsByChildCategories(
    transactions: List<RichTransaction>
  ): Dict<string, GroupEntry<List<RichTransaction>>> {
    const groups = KV.empty<string, GroupEntry<List<RichTransaction>>>()
    transactions.forEach(tx => {
      const categoryId =
        tx.category?.name && tx.category.parent?.name + ": " + tx.category.name
      if (categoryId) {
        if (groups.hasKey(categoryId)) {
          groups.get(categoryId).value.push(tx)
        } else {
          groups.put(categoryId, {
            label: `${tx.category?.name}`,
            subLabel: `${tx.category?.parent?.name}: ${tx.category?.name}`,
            value: new ArrayList([tx])
          })
        }
      }
    })
    return groups
  }

  static groupTransactionsByPeers(
    transactions: List<RichTransaction>
  ): Dict<string, GroupEntry<List<RichTransaction>>> {
    const groups = KV.empty<string, GroupEntry<List<RichTransaction>>>()
    transactions.forEach(tx => {
      const peerId = tx.peer?.name
      if (peerId) {
        if (groups.hasKey(peerId)) {
          groups.get(peerId).value.push(tx)
        } else {
          groups.put(peerId, {
            label: `${peerId}`,
            subLabel: `${peerId}`,
            value: new ArrayList([tx])
          })
        }
      }
    })
    return groups
  }

  static reduceGroupsCashFlow(
    groups: Dict<string, GroupEntry<List<RichTransaction>>>
  ): Dict<string, GroupEntry<number>> {
    const reduced = KV.empty<string, GroupEntry<number>>()
    groups.entries().forEach(e => {
      const reducedCashFlow = groups
        .get(e.key)
        .value.stream()
        .reduce((o, tx) => o + tx.cashFlow, 0)
      reduced.put(e.key, {
        label: e.value.label,
        subLabel: e.value.subLabel,
        value: reducedCashFlow
      })
    })
    return reduced
  }

  static completeDataWithPercent(
    groups: List<ChartDataEntry>
  ): List<CompleteChartDataEntry> {
    const total = groups.stream().reduce((o, c) => o + c.value, 0)
    return groups
      .stream()
      .map(e => ({...e, percent: (e.value * 100) / total}))
      .collect(toList)
  }
}
