import {LocalStorageAuthManager} from "@damntools.fr/http"
import {StorageHelper, StorageSource} from "@damntools.fr/local-storage/browser"
import {AxiosService} from "./AxiosService"

export class CustomLocalStorageManager extends LocalStorageAuthManager {
  private static INSTANCE: CustomLocalStorageManager

  protected callTokenRefresh(): Promise<string> {
    return AxiosService.getInstance()
      .post(
        "/auth/refresh",
        {},
        {
          withCredentials: true
        }
      )
      .then(res => res.data as string)
  }

  static get() {
    if (!this.INSTANCE)
      this.INSTANCE = new CustomLocalStorageManager(
        StorageHelper.with(StorageSource.WINDOW_LOCAL)
      )
    return this.INSTANCE
  }

  protected validateAuthentication(): Promise<boolean> {
    return AxiosService.getAuthenticatedInstance()
      .get("/auth/user")
      .then(() => true)
      .catch(() => false)
  }
}

export const LocalStorageManager = CustomLocalStorageManager.get()
