import {useParams} from "react-router"
import {useNavigate} from "react-router-dom"
import {DateTime} from "luxon"
import {
  BudgetProvider,
  BudgetViewProvider,
  CategoryConsumer,
  TransactionConsumer
} from "../../provider"
import React from "react"
import {BudgetPage} from "./BudgetPage"

export const BudgetLandingPage = () => {
  const {month} = useParams()
  const nav = useNavigate()
  const realMonth = month
    ? DateTime.fromFormat(month, "yyyy-MM")
    : DateTime.now().set({
        day: 1,
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0
      })

  return (
    <TransactionConsumer>
      {({transactions}) => (
        <CategoryConsumer>
          {({subCategories}) => (
            <BudgetProvider
              month={realMonth}
              transactions={transactions}
              categories={subCategories}>
              <BudgetViewProvider>
                <BudgetPage
                  month={realMonth}
                  onChangeMonth={m => nav(`/budget/${m.toFormat("yyyy-MM")}`)}
                />
              </BudgetViewProvider>
            </BudgetProvider>
          )}
        </CategoryConsumer>
      )}
    </TransactionConsumer>
  )
}
