import styles from "./TransactionTable.module.scss"
import {FlagSelector} from "../../static"
import {Optionable, Optional} from "@damntools.fr/types"
import {RichTransaction, TransactionFlag} from "@damntools.fr/wnab-data"
import React from "react"

export type TransactionFlagCellProps = {
  tx: RichTransaction
  onClickFlag: (v: Optionable<TransactionFlag>) => void
}

export const TransactionFlagCell = ({
  tx,
  onClickFlag
}: TransactionFlagCellProps) => {
  return (
    <div className={styles.Flag}>
      <span
        style={{color: tx.flag?.color || "white"}}
        title={tx.flag?.name || "Unset"}>
        &#9873;
      </span>
      <div>
        <FlagSelector
          selected={Optional.nullable(tx.flag)}
          onChange={(v: Optionable<TransactionFlag>) => onClickFlag(v)}
        />
      </div>
    </div>
  )
}
