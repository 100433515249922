import React, {ReactElement} from "react"
import {List, Lists, Optionable, Optional} from "@damntools.fr/types"
import {
  BudgetSheet,
  Category,
  RichBudget,
  RichTransaction
} from "@damntools.fr/wnab-data"
import {DateTime} from "luxon"
import {BudgetApiService} from "../service"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent, ReactComponentProps} from "@damntools.fr/react-utils"

export type BudgetProviderProps = ReactComponentProps & {
  children: ReactElement[] | ReactElement
  month: DateTime
  transactions: List<RichTransaction>
  categories: List<Category>
}

export type BudgetProviderState = {
  budgets: List<RichBudget>
  sheet: Optionable<BudgetSheet>
  refresh: () => void
}

export const BudgetContext = React.createContext({} as BudgetProviderState)

export const BudgetConsumer = BudgetContext.Consumer

export class BudgetProvider extends ReactComponent<
  BudgetProviderProps,
  BudgetProviderState
> {
  private static INSTANCE: BudgetProvider | null = null

  constructor(props: any) {
    super(props, Logging.getLogger("BudgetProvider"))
    BudgetProvider.INSTANCE = this
  }

  protected getInitialState(): BudgetProviderState {
    return {
      budgets: Lists.empty(),
      sheet: Optional.empty(),
      refresh: () => {
        return this.prepareData()
      }
    }
  }

  static refresh() {
    if (this.INSTANCE) this.INSTANCE.state.refresh()
  }

  componentDidMount() {
    return this.prepareData()
  }

  componentDidUpdate(prevProps: Readonly<BudgetProviderProps>) {
    if (
      this.hasChanged(prevProps, "transactions") ||
      this.hasChanged(prevProps, "categories") ||
      this.hasChanged(prevProps, "month")
    ) {
      return this.prepareData()
    }
  }

  prepareData() {
    return Promise.all([
      BudgetApiService.get().getBudgets(this.props.categories),
      BudgetApiService.get().getBudgetSheetForMonth(
        this.props.month,
        this.props.transactions,
        this.props.categories
      )
    ]).then(results => {
      this.logger.info("hey", results[1])
      return this.stater().applyState({
        budgets: results[0],
        sheet: Optional.nullable(results[1])
      })
    })
  }

  render() {
    return (
      <BudgetContext.Provider value={this.state}>
        {this.props.children}
      </BudgetContext.Provider>
    )
  }
}
