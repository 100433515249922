import React from "react"
import styles from "./BudgetInfoTable.module.scss"
import {List} from "@damntools.fr/types"
import {PriceLabel} from "../PriceLabel"
import {BudgetSelection, BudgetViewConsumer} from "../../../provider"
import {BudgetEntry, BudgetSheet} from "@damntools.fr/wnab-data"

export type BudgetInfoTableProps = {
  budgetSheet: BudgetSheet
}

export class BudgetInfoTable extends React.Component<
  BudgetInfoTableProps,
  any
> {
  render() {
    return (
      <BudgetViewConsumer>
        {({selectedCategories}) => {
          let budgeted = this.getBudgeted(selectedCategories)
          let activity = this.getActivity(selectedCategories)
          let lastMonth = this.getLastMonth(selectedCategories)
          let available = this.getAvailable(selectedCategories)
          return (
            <div className={styles.InfoTable}>
              <div>
                <div>
                  <span>Left from month before</span>
                  <span>
                    <PriceLabel
                      withBackground={true}
                      value={lastMonth}
                      status={"default"}
                    />
                  </span>
                </div>
                <div>
                  <span>Budgeted</span>
                  <span>
                    <PriceLabel
                      withBackground={true}
                      value={budgeted}
                      status={"default"}
                    />
                  </span>
                </div>
                <div>
                  <span>Spending</span>
                  <span>
                    <PriceLabel
                      withBackground={true}
                      value={activity}
                      status={"default"}
                    />
                  </span>
                </div>
                <div>
                  <span style={{fontWeight: "bold"}}>Available</span>
                  <span>
                    <PriceLabel
                      withBackground={true}
                      value={available}
                      status={"default"}
                    />
                  </span>
                </div>
              </div>
            </div>
          )
        }}
      </BudgetViewConsumer>
    )
  }

  private getActivity(selectedCategories: List<BudgetSelection>) {
    return this.props.budgetSheet.categoryBudgets
      .values()
      .stream()
      .filter(e => this.filter(e, selectedCategories))
      .reduce((o, c) => o + c.budget.activity, 0)
  }

  private getAvailable(selectedCategories: List<BudgetSelection>) {
    return this.props.budgetSheet.categoryBudgets
      .values()
      .stream()
      .filter(e => this.filter(e, selectedCategories))
      .reduce((o, c) => o + c.budget.available, 0)
  }

  private getBudgeted(selectedCategories: List<BudgetSelection>) {
    return this.props.budgetSheet.categoryBudgets
      .values()
      .stream()
      .filter(e => this.filter(e, selectedCategories))
      .reduce((o, c) => o + c.budget.budgeted, 0)
  }

  private getLastMonth(selectedCategories: List<BudgetSelection>) {
    return this.props.budgetSheet.categoryBudgets
      .values()
      .stream()
      .filter(e => this.filter(e, selectedCategories))
      .map(e => e.lastMonth)
      .reduce((o, c) => o + this.getDeepAvailableForEntry(c), 0)
  }

  private getDeepAvailableForEntry(entry?: BudgetEntry): number {
    let cpt = (entry?.budget.budgeted || 0) + (entry?.budget.activity || 0)
    if (entry?.lastMonth) cpt += this.getDeepAvailableForEntry(entry.lastMonth)
    return cpt
  }

  private filter(e: BudgetEntry, selectedCategories: List<BudgetSelection>) {
    return (
      selectedCategories
        .stream()
        .filter(s => s.selected)
        .count() === 0 ||
      selectedCategories
        .stream()
        .some(s => s.categoryId === e.budget.category.id && s.selected)
    )
  }
}
