import React from "react"
import {
  CheckboxInput,
  ChoiceSelector,
  TextInput,
  VD
} from "@damntools.fr/react-inputs"
import {Optionable, Optional, toList} from "@damntools.fr/types"
import {Account, AccountType, CreateAccountDto} from "@damntools.fr/wnab-data"
import {AccountApiService} from "../../../service"
import {StyleSize} from "@damntools.fr/react-utils"
import {AccountProvider, TransactionProvider} from "../../../provider"
import {PopinForm, PopinFormProps, PopinFormState} from "../PopinForm"
import {Check, Checks, Validator} from "@damntools.fr/validators"
import {getAccountTypeValues} from "../../../utils"
import {Strings, tsl} from "../../../i18n"

export type AccountCreationViewProps = PopinFormProps & {
  account: Optionable<Account>
}

export type AccountCreationViewState = PopinFormState & {
  closed: Optionable<boolean>
  name: Optionable<string>
  type: Optionable<AccountType>
}

export const openAccountViewPopup = (account?: Account) =>
  PopinForm.open<AccountCreationViewProps, AccountCreationView>(
    {
      account: Optional.nullable(account),
      title: account
        ? `${tsl(Strings.resources.account.update)} : ${account.name}`
        : tsl(Strings.resources.account.create)
    },
    AccountCreationView
  )

export class AccountCreationView extends PopinForm<
  AccountCreationViewProps,
  AccountCreationViewState,
  AccountCreationViewState
> {
  constructor(props: AccountCreationViewProps) {
    super(props, "AccountCreationView")
  }

  protected getInitialState(): AccountCreationViewState {
    if (this.props.account.isPresent()) {
      const account = this.props.account.get()
      return {
        closed: Optional.of(account.closed),
        name: Optional.of(account.name),
        type: Optional.of(account.type)
      }
    } else {
      return {
        closed: Optional.of(false),
        name: Optional.empty(),
        type: Optional.empty()
      }
    }
  }

  protected getValidator(): Validator<AccountCreationViewState> {
    return new Validator()
      .addCheck(
        Check.field("name").with(
          Check.optional()
            .provided(tsl(Strings.popins.accountEdit.error.emptyName))
            .valueCheck(
              Checks.String.matches(/^[a-zA-Z0-9.\s,_-]+$/g),
              tsl(Strings.popins.accountEdit.error.badNamePattern)
            )
        )
      )
      .addCheck(
        Check.field("type").with(
          Check.optional().provided(
            tsl(Strings.popins.accountEdit.error.emptyType)
          )
        )
      )
  }

  getForm() {
    return (
      <div>
        {this.getRow(
          tsl(Strings.popins.accountEdit.fields.name),
          <TextInput
            dark={true}
            onChange={v => this.onChangeOptional("name", v)}
            hideFormat={true}
            value={this.state.name.map(VD)}
          />
        )}
        {this.getRow(
          tsl(Strings.popins.accountEdit.fields.type),
          <ChoiceSelector
            dark={true}
            onChange={v => this.onChangeOptional("type", v)}
            values={getAccountTypeValues()}
            selectedValues={this.state.type.map(VD).toStream().collect(toList)}
          />
        )}
        {this.getRow(
          tsl(Strings.popins.accountEdit.fields.closed),
          <div>
            <CheckboxInput
              size={StyleSize.px(20)}
              dark={true}
              onChange={v => this.onChangeOptional("closed", v)}
              checked={this.state.closed.orElseReturn(false)}
            />
          </div>
        )}
      </div>
    )
  }

  protected onSuccess(model: AccountCreationViewState) {
    const account: CreateAccountDto = {
      closed: model.closed.orElseReturn(false),
      name: model.name.get(),
      type: model.type.map(t => t.key().toUpperCase()).get()
    }
    if (this.props.account.isPresent()) {
      const id = this.props.account.map(a => a.id).get()
      return AccountApiService.get()
        .updateAccount({...account, id})
        .then(() => AccountProvider.refresh())
        .then(() => TransactionProvider.refresh())
        .catch(err =>
          this.processError(
            err,
            tsl(Strings.popins.accountEdit.error.updateError)
          )
        )
    } else {
      return AccountApiService.get()
        .createAccount(account)
        .then(() => AccountProvider.refresh())
        .then(() => TransactionProvider.refresh())
        .catch(err =>
          this.processError(
            err,
            tsl(Strings.popins.accountEdit.error.createError)
          )
        )
    }
  }
}
