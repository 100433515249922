import React from "react"
import {ColorPicker, TextInput, VD} from "@damntools.fr/react-inputs"
import {Optionable, Optional} from "@damntools.fr/types"
import {
  CreateTransactionFlagDto,
  TransactionFlag
} from "@damntools.fr/wnab-data"
import {TransactionFlagApiService} from "../../../service"
import {ColorResult} from "react-color"
import {TransactionProvider} from "../../../provider"
import {PopinForm, PopinFormProps, PopinFormState} from "../PopinForm"
import {Check, Checks, Validator} from "@damntools.fr/validators"
import {Strings, tsl} from "../../../i18n"
import {HiddenToggle} from "../../static"

export type FlagEditViewProps = PopinFormProps & {
  flag: Optionable<TransactionFlag>
}

export type FlagEditViewState = PopinFormState & {
  hidden: Optionable<boolean>
  name: Optionable<string>
  color: Optionable<string>
}

export const openTxFlagViewPopup = (flag?: TransactionFlag) =>
  PopinForm.open<FlagEditViewProps, FlagEditView>(
    {
      flag: Optional.nullable(flag),
      title: flag
        ? `${tsl(Strings.resources.flag.update)} : ${flag.name}`
        : tsl(Strings.resources.flag.create)
    },
    FlagEditView
  )

export class FlagEditView extends PopinForm<
  FlagEditViewProps,
  FlagEditViewState,
  FlagEditViewState
> {
  constructor(props: FlagEditViewProps) {
    super(props, "FlagEditView")
  }

  protected getInitialState(): FlagEditViewState {
    if (this.props.flag.isPresent()) {
      const flag = this.props.flag.get()
      return {
        hidden: Optional.of(flag.hidden),
        name: Optional.of(flag.name),
        color: Optional.of(flag.color)
      }
    } else {
      return {
        hidden: Optional.of(false),
        name: Optional.empty(),
        color: Optional.empty()
      }
    }
  }

  protected getValidator(): Validator<FlagEditViewState> {
    return new Validator().addCheck(
      Check.field("name").with(
        Check.optional()
          .provided(tsl(Strings.popins.flagEdit.error.emptyName))
          .valueCheck(
            Checks.String.matches(/^[a-zA-Z0-9.\s,_-]+$/g),
            tsl(Strings.popins.flagEdit.error.badNamePattern)
          )
      )
    )
  }

  getForm() {
    return (
      <div>
        {this.getRow(
          tsl(Strings.popins.flagEdit.fields.name),
          <TextInput
            dark={true}
            onChange={v => this.onChangeOptional("name", v)}
            hideFormat={true}
            value={this.state.name.map(VD)}
          />
        )}
        {this.getRow(
          tsl(Strings.popins.flagEdit.fields.color),
          <div style={{height: "20px"}}>
            <ColorPicker
              dark
              value={this.state.color}
              onChange={(v: Optionable<ColorResult>) => this.onChangeColor(v)}
            />
          </div>
        )}
        {this.getRow(
          tsl(Strings.popins.flagEdit.fields.hidden),
          <HiddenToggle
            hidden={this.state.hidden.orElseReturn(false)}
            onChange={v => this.onChangeOptional("hidden", v)}
          />
        )}
      </div>
    )
  }

  protected onSuccess(model: FlagEditViewState) {
    const flag: CreateTransactionFlagDto = {
      hidden: model.hidden.orElseReturn(false),
      name: model.name.get(),
      color: model.color.orElseReturn("#ffffff")
    }
    if (this.props.flag.isPresent()) {
      const id = this.props.flag.map(f => f.id).get()
      void TransactionFlagApiService.get()
        .update({...flag, id})
        .then(() => TransactionProvider.refresh())
        .catch(err =>
          this.processError(err, tsl(Strings.popins.flagEdit.error.updateError))
        )
    } else {
      void TransactionFlagApiService.get()
        .create(flag)
        .then(() => TransactionProvider.refresh())
        .catch(err =>
          this.processError(err, tsl(Strings.popins.flagEdit.error.createError))
        )
    }
  }

  private onChangeColor(value: Optionable<ColorResult>) {
    this.setState({color: value.map(v => v.hex)}, () => this.store())
  }
}
