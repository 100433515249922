import {BaseStrings} from "./base"

export const en: BaseStrings = {
  transactionTable: {
    errors: {
      setFlag: "Could not update flag for transaction",
      setStatus: "Could not update status for transaction"
    },
    contextMenu: {
      filterByPeer: "Filter by {{Strings.resources.peer.single}}",
      filterByCategory: "Filter by {{Strings.resources.category.single}}",
      filterByDescription: "Filter by Description",
      filterByAccount: "Filter by {{Strings.resources.account.single}}"
    },
    columns: {
      account: "Account",
      cashFlow: "Cash flow",
      category: "Category",
      date: "Date",
      description: "Description",
      flag: "Flag",
      peer: "Peer / Transfer",
      status: "Status"
    },
    toolbar: {
      clearSearch: "Reset search",
      activeSearch: "Active",
      add: "Add",
      clear: "Clear",
      delete: "Delete",
      duplicate: "Duplicate",
      edit: "Edit",
      searchPlaceholder: "Filter...",
      searchTypes: {
        account: "account",
        all: "all",
        category: "category",
        complex: "complex",
        description: "description",
        peer: "peer"
      }
    }
  },
  basic: {
    yes: "Yes",
    no: "No"
  },
  views: {
    settings: {
      title: "Account settings",
      email: "Email",
      importedBudgets: "Budgets already imported",
      importedTransactions: "Transactions already imported",
      lastLogin: "Last login",
      login: "Login",
      created: "Account creation"
    },
    budget: {
      title: "Budget"
    },
    cartouches: {
      account: {
        buttons: {
          configure: "Configure",
          reconcile: "Reconcile",
          clearAll: "Clear all",
          close: "Close",
          open: "Open"
        }
      },
      balance: {
        cleared: "Cleared",
        uncleared: "Uncleared",
        available: "Available"
      }
    },
    transactions: {
      title: "Transactions: "
    },
    category: {
      addChild: "Add child",
      addParent: "Add parent",
      moveCategories: "Move category transactions",
      hidden: "Currently hidden",
      notHidden: "Not hidden",
      edit: "Edit",
      delete: "Delete",
      title: "Category administration",
      hiddenErrorMessage: "Could not update hidden value !"
    },
    peer: {
      add: "Add peer",
      edit: "Edit",
      delete: "Delete",
      hidden: "Currently hidden",
      notHidden: "Not hidden",
      title: "Peer administration",
      hiddenErrorMessage: "Could not update hidden value !"
    },
    flag: {
      add: "Add flag",
      edit: "Edit",
      delete: "Delete",
      hidden: "Currently hidden",
      notHidden: "Not hidden",
      title: "Flag administration",
      hiddenErrorMessage: "Could not update hidden value !"
    }
  },
  preview: {
    message:
      "This is a free beta version. If you like it, the service will cost approximately 2€/month at launch. You will be able to export your data if I have failed to convince you against YNAB and does not want to pay."
  },
  title: "YDNY!",
  longTitle: "You don't need YNAB!",
  menu: {
    description: {
      greeting: "Hello",
      text: "I'm sure you have plenty of reasons to have one and you will probably discover new ones all along the journey. No need to be assertive in the name ;)",
      title: "stands for"
    },
    donate: "Donate",
    elements: {budget: "Budget", overview: "Overview", reports: "Reports"},
    options: "Options",
    report: "Report a bug",
    toolbar: {
      manage: "Manage",
      expand: "Click to expand",
      hide: "Click to hide"
    },
    accounts: {
      edit: "Edit",
      add: "Add account",
      budget: "Budget accounts",
      closed: "Closed accounts",
      tracking: "Tracking accounts"
    }
  },
  resources: {
    transaction: {
      plural: "Transactions",
      single: "Transaction",
      update: "Update transaction",
      create: "Create transaction",
      recurring: {
        annually: "Annually",
        daily: "Daily",
        everyFirstOfMonth: "Every first of the month",
        everyMonday: "Every monday",
        everySaturday: "Every saturday",
        monthly: "Monthly",
        onceEverySixMonth: "Once every six month",
        onceEveryThreeMonth: "Once every three month",
        onceEveryTwoMonth: "Once every two month",
        weekly: "Weekly"
      },
      status: {cleared: "Cleared", uncleared: "Uncleared"}
    },
    account: {
      plural: "Accounts",
      single: "Account",
      update: "Update account",
      create: "Create account",
      types: {
        cash: "Cash",
        daily: "Daily usage",
        investment: "Investment",
        savings: "Savings"
      }
    },
    category: {
      plural: "Categories",
      single: "Category",
      update: "Update category",
      create: "Create category"
    },
    flag: {
      plural: "Flags",
      single: "Flag",
      update: "Update flag",
      create: "Create flag"
    },
    peer: {
      plural: "Peers",
      single: "Peer",
      update: "Update peer",
      create: "Create peer",
      types: {
        entity: "Entity",
        person: "Person"
      }
    }
  },

  popins: {
    transactions: {
      delete: {
        confirmMessage: "Are you sure to delete those {{count}} {{type}} ?",
        errorMessage: "Could not delete transactions !",
        successMessage: "Successfully deleted {{count}} {{type}}.",
        fullMessage: ""
      },
      clear: {
        successMessage: "Successfully deleted {{count}} {{type}}.",
        errorMessage: "Could not clear transactions !",
        confirmMessage: "Are you sure to clear those {{count}} {{type}} ?",
        fullMessage: ""
      },
      duplicate: {
        errorMessage: "Could not duplicate transactions !",
        successMessage: "Successfully deleted {{count}} {{type}}.",
        confirmMessage: "Are you sure to duplicate those {{count}} {{type}} ?",
        fullMessage: ""
      }
    },
    closeAccount: {
      title: "Are you sure to close account ?",
      error: "Could not close account"
    },
    openAccount: {
      title: "Are you sure to open account ?",
      error: "Could not open account"
    },
    clearAllForAccount: {
      title: "Are you sure to clear all transactions ?",
      error: "Could not clear transactions"
    },
    moveCategoryTransactions: {
      title: "Move category transactions",
      description:
        "You can select all transactions of the selected source category. On confirmation, all those transactions will be attached to the target category.",
      successMessage: "{{count}} transactions moved !",
      fields: {
        source: "Source",
        target: "Target"
      },
      error: {
        emptySource: "Source must be provided !",
        emptyTarget: "Target must be provided !",
        updateError: "Could not move transactions !"
      }
    },
    categoryEdit: {
      error: {
        emptyName: "Name should be provided !",
        badNamePattern:
          "Name should only contain letters, numbers, dots, spaces, dashes or commas !",
        parentShouldBeProvided:
          "Category can not be updated to a parent category !",
        createError: "Could not create category !",
        updateError: "Could not update category !"
      },
      fields: {
        name: "Name",
        hidden: "Hidden",
        parent: "Parent"
      }
    },
    transactionEdit: {
      error: {
        createError: "Could not create flag !",
        updateError: "Could not update flag !",
        emptyAmount: "Amount should be provided !",
        emptyStatus: "Status should be provided !",
        emptyDate: "Date should be provided !",
        emptyAccount: "Account should be provided !"
      },
      fields: {
        account: "Account",
        amount: "Amount",
        category: "Category",
        date: "Date",
        description: "Description",
        flag: "Flag",
        peer: "Peer",
        repeat: "Repeat",
        status: "Status"
      }
    },
    flagEdit: {
      error: {
        emptyName: "Name should be provided !",
        badNamePattern:
          "Name should only contain letters, numbers, dots, spaces, dashes or commas !",
        createError: "Could not create flag !",
        updateError: "Could not update flag !"
      },
      fields: {
        name: "Name",
        hidden: "Hidden",
        color: "Color"
      }
    },
    reconcileAccount: {
      title: "Reconcile account",
      error: "Could not reconcile account !",
      amount: "Current balance"
    },
    accountEdit: {
      error: {
        emptyName: "Name should be provided !",
        emptyType: "Type should be provided !",
        badNamePattern:
          "Name should only contain letters, numbers, dots, spaces, dashes or commas !",
        createError: "Could not create account !",
        updateError: "Could not update account !"
      },
      fields: {
        name: "Name",
        closed: "Closed",
        type: "Type"
      }
    },
    peerEdit: {
      error: {
        emptyName: "Name should be provided !",
        emptyType: "Type should be provided !",
        badNamePattern:
          "Name should only contain letters, numbers, dots, spaces, dashes or commas !",
        createError: "Could not create peer !",
        updateError: "Could not update peer !"
      },
      fields: {
        name: "Name",
        hidden: "Hidden",
        type: "Type"
      }
    },
    deletion: {
      confirmMessage: "Are you sure you want to delete this {{type}} ?",
      errorMessage: "Could not delete this {{type}} !",
      successMessage: "{{type}} deleted",
      category: {
        fullMessage:
          "Attached transactions will be detached and will need to be re-attributed. Use the move function before if you want to track these transactions.",
        parentMessage: "Child categories will be deleted as well !"
      },
      peer: {
        fullMessage:
          "Attached transactions will be detached and will need to be re-attributed."
      },
      flag: {
        fullMessage:
          "Attached transactions will be detached and will need to be re-attributed, if you want so."
      }
    }
  },
  landing: {
    anonymous: {
      header: "Anonymous"
    },
    auth: {
      type: {
        user: "User"
      }
    }
  },
  authenticated: {
    logout: "Log out",
    openAccountSettings: "Account settings"
  }
}
