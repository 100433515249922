export const PieChartColors = [
  "#D33731",
  "#B76423",
  "#C4A52D",
  "#90B421",
  "#61c3a7",
  "#2485AF",
  "#3d3dbf",
  "#8158b5",
  "#cb63cf"
]
