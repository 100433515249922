import React from "react"
import ReactDOM from "react-dom/client"
import "react-datepicker/dist/react-datepicker.css"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import {Globals} from "@damntools.fr/react-globals"
import {App} from "./App"
import {AuthenticationProvider} from "./provider"
import {initI18next, initLogging} from "./config"

initLogging()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

Globals.init("/globals.json")
  .then(() => initI18next())
  .then(() => {
    root.render(
        <AuthenticationProvider>
          <App />
        </AuthenticationProvider>
    )
  })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
