import {CssClass} from "@damntools.fr/utils-simple"
import styles from "./TransactionTable.module.scss"
import {
  ContextMenuProvider,
  FlexColumnBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell,
  Table,
  TableCell,
  TableCellStyle,
  TableScroll,
  TableSelectionConfig,
  TableStyle
} from "@damntools.fr/react-layout"
import {StyleSize} from "@damntools.fr/react-utils"
import {
  Account,
  RichTransaction,
  TransactionFlag,
  TransactionStatus
} from "@damntools.fr/wnab-data"
import {TransactionFlagCell} from "./TransactionFlagCell"
import {TransactionStatusCell} from "./TransactionStatusCell"
import {
  isTodayOrBefore,
  TransactionApiService,
  TransactionFilter
} from "../../../service"
import React from "react"
import {List, Optionable, Optional, toList} from "@damntools.fr/types"
import {
  AccountProvider,
  TransactionProvider,
  TransactionTableConsumer,
  TransactionTableProviderState
} from "../../../provider"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {PriceView} from "../../static"
import {TransactionTableToolbar} from "./TransactionTableToolbar"
import {Strings, tsl} from "../../../i18n"
import {formatTxPeer} from "./Functions"
import {TransactionTableContextMenu} from "./ContextMenu"

export type TransactionTableV2Props = {
  data: List<RichTransaction>
  showAccount?: boolean
  hideCategory?: boolean
  currentAccount?: Account
}

function onClickFlag(tx: RichTransaction, value: Optionable<TransactionFlag>) {
  return TransactionApiService.get()
    .updateTx({
      id: tx.id,
      flagId: value
        .flatMap(v =>
          tx.flag?.id === v.id ? Optional.empty<number>() : Optional.of(v.id)
        )
        .orElseUndefined()
    })
    .then(() => AccountProvider.refresh())
    .then(() => TransactionProvider.refresh())
    .catch(() =>
      AlertProvider.submit(
        Notification.error().Subtitle(
          tsl(Strings.transactionTable.errors.setFlag)
        )
      )
    )
}

function onClickStatus(tx: RichTransaction) {
  let status: TransactionStatus | null = null
  if (TransactionStatus.UNCLEARED.equals(tx.status))
    status = TransactionStatus.CLEARED
  else if (TransactionStatus.CLEARED.equals(tx.status))
    status = TransactionStatus.UNCLEARED
  if (status) {
    TransactionApiService.get()
      .updateTx({id: tx.id, status: status.key().toUpperCase()})
      .then(() => AccountProvider.refresh())
      .then(() => TransactionProvider.refresh())
      .catch(() =>
        AlertProvider.submit(
          Notification.error().Subtitle(
            tsl(Strings.transactionTable.errors.setStatus)
          )
        )
      )
  }
}

export const TransactionTable = ({
  currentAccount,
  data,
  showAccount,
  hideCategory
}: TransactionTableV2Props) => {
  const cellClasses = CssClass.from(styles.TableCell)
  const headerClasses = CssClass.from(styles.TableHeaderCell)
  return (
    <TransactionTableConsumer>
      {(provider: TransactionTableProviderState) => {
        const filtered = TransactionFilter.filter(
          data,
          provider.search,
          provider.searchType
        )
        return (
          <ContextMenuProvider>
            <FlexColumnBlock className={styles.TableContainer}>
              <ShrinkFlexCell>
                <TransactionTableToolbar
                  transactions={data}
                  account={Optional.nullable(currentAccount)}
                />
              </ShrinkFlexCell>
              <GrowShrinkFlexCell hideOverflow>
                <Table
                  data={filtered}
                  rowIdentifierFn={r => r.id}
                  rowStyleProvider={(r: RichTransaction) =>
                    CssClass.classIf(styles.Future, !isTodayOrBefore(r))
                  }>
                  <TableSelectionConfig
                    onSelected={(selected: List<number>) =>
                      provider.setSelection(
                        selected
                          .stream()
                          .map(s =>
                            filtered
                              .stream()
                              .findOptional(tx => tx.id === s)
                              .get()
                          )
                          .collect(toList)
                      )
                    }
                  />
                  <TableScroll scrollDownElementAddCount={50} />
                  <TableStyle
                    rowHeight={StyleSize.px(10)}
                    classNames={cellClasses}
                    headerClassNames={headerClasses}
                  />
                  <TableCell
                    title={tsl(Strings.transactionTable.columns.flag)}
                    rowField={"flag"}
                    // filtered
                    // sorted
                    disableSelectionOnClick
                    displayFn={(r: RichTransaction) => (
                      <div>
                        <TransactionFlagCell
                          tx={r}
                          onClickFlag={v => onClickFlag(r, v)}
                        />
                      </div>
                    )}>
                    <TableCellStyle
                      headerAlign={"center"}
                      align={"center"}
                      width={StyleSize.px(50)}
                    />
                  </TableCell>
                  <TableCell
                    title={tsl(Strings.transactionTable.columns.date)}
                    rowField={"date"}
                    // filtered
                    // sorted
                    displayFn={(r: RichTransaction) =>
                      r.date.toFormat("yyyy-MM-dd")
                    }>
                    <TableCellStyle width={StyleSize.px(90)} />
                  </TableCell>
                  {showAccount ? (
                    <TableCell
                      title={tsl(Strings.transactionTable.columns.account)}
                      rowField={"account"}
                      // filtered
                      // sorted
                      displayFn={(r: RichTransaction) => r.account?.name || ""}>
                      <TableCellStyle width={StyleSize.px(200)} />
                    </TableCell>
                  ) : (
                    (undefined as any)
                  )}
                  <TableCell
                    title={tsl(Strings.transactionTable.columns.peer)}
                    valueLoader={r => r.peer || r.transferAccount}
                    // filtered
                    // sorted
                    contextMenu={TransactionTableContextMenu(
                      provider,
                      showAccount
                    )}
                    displayFn={(r: RichTransaction) => formatTxPeer(r)}>
                    <TableCellStyle width={StyleSize.px(220)} />
                  </TableCell>
                  {!hideCategory ? (
                    <TableCell
                      title={tsl(Strings.transactionTable.columns.category)}
                      rowField={"category"}
                      // filtered
                      // sorted
                      displayFn={(r: RichTransaction) =>
                        r.category?.pretty() || ""
                      }>
                      <TableCellStyle width={StyleSize.px(300)} />
                    </TableCell>
                  ) : (
                    (undefined as any)
                  )}
                  <TableCell
                    title={tsl(Strings.transactionTable.columns.description)}
                    rowField={"description"}
                    // filtered
                    sorted>
                    <TableCellStyle />
                  </TableCell>
                  <TableCell
                    title={tsl(Strings.transactionTable.columns.cashFlow)}
                    rowField={"cashFlow"}
                    // filtered
                    sorted
                    displayFn={(r: RichTransaction) => (
                      <PriceView value={r.cashFlow} />
                    )}>
                    <TableCellStyle
                      width={StyleSize.px(100)}
                      headerAlign={"right"}
                      align={"right"}
                    />
                  </TableCell>
                  <TableCell
                    title={tsl(Strings.transactionTable.columns.status)}
                    rowField={"status"}
                    // filtered
                    sorted
                    disableSelectionOnClick
                    valueHoverTitle={r => r.status.key()}
                    displayFn={(r: RichTransaction) => (
                      <TransactionStatusCell
                        tx={r}
                        isFutureTx={!isTodayOrBefore(r)}
                        onClick={() => onClickStatus(r)}
                      />
                    )}>
                    <TableCellStyle
                      width={StyleSize.px(60)}
                      headerAlign={"center"}
                      align={"center"}
                    />
                  </TableCell>
                </Table>
              </GrowShrinkFlexCell>
            </FlexColumnBlock>
          </ContextMenuProvider>
        )
      }}
    </TransactionTableConsumer>
  )
}
