import {
  ReportGroupBy,
  ReportPageConsumer,
  SpecificTimeFilter,
  TimeFilter,
  TimeFilters
} from "../../provider"
import {DateTime} from "luxon"
import styles from "./ReportPage.module.scss"
import {MultiLinkBubble} from "../../component"
import {
  png_bank,
  png_category,
  png_menu_edit,
  png_peer_logo,
  png_time
} from "../../assets"
import {RGBA, StyleSize} from "@damntools.fr/react-utils"
import {Optionable} from "@damntools.fr/types"
import React from "react"

export function ReportPageFilters() {
  return (
    <ReportPageConsumer>
      {({
        setTimeFilter,
        timeFilter,
        setGroupBy,
        groupBy
      }) => {
        const now = DateTime.now()
        return (
          <div className={styles.Filters}>
            <MultiLinkBubble
              icon={png_time}
              title={"Time filters"}
              iconSize={StyleSize.px(18)}
              bgColor={RGBA(255, 255, 255, 0.1)}
              links={[
                getTimeFilterLink(
                  "Today",
                  TimeFilters.today(now),
                  timeFilter,
                  setTimeFilter
                ),
                getTimeFilterLink(
                  "This week",
                  TimeFilters.thisWeek(now),
                  timeFilter,
                  setTimeFilter
                ),
                getTimeFilterLink(
                  "This month",
                  TimeFilters.thisMonth(now),
                  timeFilter,
                  setTimeFilter
                ),
                getTimeFilterLink(
                  "This year",
                  TimeFilters.thisYear(now),
                  timeFilter,
                  setTimeFilter
                ),
                getTimeFilterLink(
                  "Last year",
                  TimeFilters.lastYear(now),
                  timeFilter,
                  setTimeFilter
                ),
                getTimeFilterLink(
                  "Everything",
                  TimeFilters.everything(now),
                  timeFilter,
                  setTimeFilter
                ),
                getTimeFilterLink(
                  "Custom",
                  TimeFilters.custom(now),
                  timeFilter,
                  setTimeFilter,
                  png_menu_edit
                )
              ]}
            />
            <MultiLinkBubble
              icon={png_category}
              title={"By category"}
              iconSize={StyleSize.px(18)}
              bgColor={RGBA(255, 255, 255, 0.1)}
              links={[
                getGroupByLink("Parent", "parentCategory", groupBy, setGroupBy),
                getGroupByLink("Child", "childCategory", groupBy, setGroupBy)
              ]}
            />
            <MultiLinkBubble
              icon={png_bank}
              title={"By account"}
              iconSize={StyleSize.px(18)}
              bgColor={RGBA(255, 255, 255, 0.1)}
              links={[
                getGroupByLink("All", "account", groupBy, setGroupBy),
                getGroupByLink("Tracking", "coldAccount", groupBy, setGroupBy),
                getGroupByLink("Budget", "hotAccount", groupBy, setGroupBy),
                getGroupByLink(
                  "Custom",
                  "customAccount",
                  groupBy,
                  setGroupBy,
                  png_menu_edit
                )
              ]}
            />
            <MultiLinkBubble
              icon={png_peer_logo}
              title={"By peer"}
              iconSize={StyleSize.px(18)}
              bgColor={RGBA(255, 255, 255, 0.1)}
              links={[
                getGroupByLink("All", "peer", groupBy, setGroupBy),
                getGroupByLink("Custom", "customPeer", groupBy, setGroupBy)
              ]}
            />
          </div>
        )
      }}
    </ReportPageConsumer>
  )
}

function getTimeFilterLink(
  title: string,
  filter: TimeFilter,
  currentFilter: Optionable<TimeFilter>,
  setTimeFilter: (timeFilter?: TimeFilter) => Promise<any>,
  icon?: string
) {
  return {
    title: title,
    icon,
    iconSize: StyleSize.px(16),
    onlyDisplayIcon: !!icon,
    onClick: () => setTimeFilter(filter),
    active: checkTimeFilterActive(filter.label as any, currentFilter)
  }
}

function getGroupByLink(
  title: string,
  groupBy: ReportGroupBy,
  currentGroupBy: Optionable<ReportGroupBy>,
  setGroupBy: (groupBy?: ReportGroupBy) => Promise<any>,
  icon?: string
) {
  return {
    title: title,
    icon,
    iconSize: StyleSize.px(16),
    onlyDisplayIcon: !!icon,
    onClick: () => setGroupBy(groupBy),
    active: checkGroupByActive(groupBy, currentGroupBy)
  }
}

function checkTimeFilterActive(
  toCheck: SpecificTimeFilter,
  current: Optionable<TimeFilter>
) {
  return current.filter(f => f.label === toCheck).isPresent()
}

function checkGroupByActive(
  toCheck: ReportGroupBy,
  current: Optionable<ReportGroupBy>
) {
  return current.filter(f => f === toCheck).isPresent()
}
