import {
  ArrayList,
  compare,
  KV,
  List,
  Streams,
  toList
} from "@damntools.fr/types"
import {
  Account,
  Category,
  CreateTransactionDto,
  Peer,
  RecurringTransactionStep,
  RichTransaction,
  Transaction,
  TransactionDto,
  TransactionDtoMapper,
  TransactionFlag,
  TransactionStatus,
  UpdateTransactionDto,
  UserProfile
} from "@damntools.fr/wnab-data"
import {AxiosService} from "../auth"
import {AxiosWrapper} from "@damntools.fr/http"
import {PeerApiService} from "./PeerApiService"
import {CategoryApiService} from "./CategoryApiService"
import {TransactionFlagApiService} from "./TransactionFlagApiService"
import {AccountApiService} from "./AccountApiService"
import {DateTimeTimestampMapper} from "@damntools.fr/data"

export class TransactionApiService {
  static INSTANCE: TransactionApiService | null = null
  private readonly axios: AxiosWrapper

  constructor() {
    this.axios = AxiosService.getAuthenticatedInstance().child({
      baseURL: "/resource/transaction"
    })
  }

  getTxs(): Promise<List<Transaction>> {
    return this.axios
      .get("/")
      .then(res => new ArrayList<TransactionDto>(res.data))
      .then(res => {
        return res
          .stream()
          .map(a => {
            return TransactionDtoMapper.get().mapTo(a)
          })
          .collect(toList)
      })
  }

  getRichTxs(): Promise<List<RichTransaction>> {
    const promises = [
      PeerApiService.get().getPeers(),
      CategoryApiService.get().getAll(),
      TransactionFlagApiService.get().getFlags(),
      AccountApiService.get().getAccounts(),
      this.axios.get("/").then(res => res.data)
    ]
    return Promise.all(promises).then((results: any) => {
      const peers = (results[0] as List<Peer>).stream()
      const categories = (results[1] as List<Category>).stream()
      const flags = (results[2] as List<TransactionFlag>).stream()
      const accounts = (results[3] as List<Account>).stream()
      const txs = results[4] as Array<TransactionDto>
      const peerCache = KV.empty<number, Peer>()
      const categoryCache = KV.empty<number, Category>()
      const flagCache = KV.empty<number, TransactionFlag>()
      const accountCache = KV.empty<number, Account>()
      return Streams.from(txs)
        .map(tx => {
          const account =
            accountCache.get(tx.accountId as number) ||
            accounts
              .findOptional(a => a.id === tx.accountId)
              .peek(a => accountCache.put(a.id, a))
              .orElseUndefined()
          const transferAccount: Peer = (accountCache.get(
            tx.transferAccountId as number
          ) ||
            accounts
              .findOptional(a => a.id === tx.transferAccountId)
              .peek(a => accountCache.put(a.id, a))
              .orElseUndefined()) as any
          const flag =
            flagCache.get(tx.flagId as number) ||
            flags
              .findOptional(f => f.id === tx.flagId)
              .peek(f => flagCache.put(f.id, f))
              .orElseUndefined()
          const category =
            categoryCache.get(tx.categoryId as number) ||
            categories
              .findOptional(c => c.id === tx.categoryId)
              .peek(c => categoryCache.put(c.id, c))
              .orElseUndefined()
          const peer =
            peerCache.get(tx.peerId as number) ||
            peers
              .findOptional(p => p.id === tx.peerId)
              .peek(p => peerCache.put(p.id, p))
              .orElseUndefined()
          return new RichTransaction({
            cashFlow: tx.cashFlow,
            date: DateTimeTimestampMapper.get().mapTo(tx.date),
            description: tx.description,
            id: tx.id,
            repeat:
              (tx.repeat &&
                RecurringTransactionStep.fromValue<
                  string,
                  RecurringTransactionStep
                >(tx.repeat)) ||
              undefined,
            repeated: tx.repeated,
            status: TransactionStatus.fromValue(tx.status),
            transferAccount,
            category,
            owner: new UserProfile({
              email: "",
              id: 0,
              login: "",
              password: "",
              roles: new ArrayList()
            }),
            peer,
            flag,
            account
          })
        })
        .sort(
          (a, b) =>
            compare(b.date.toMillis(), a.date.toMillis()) ||
            compare(a.account?.name, b.account?.name) ||
            compare(a.peer?.name, b.peer?.name) ||
            compare(a.category?.name, b.category?.name)
        )
        .collect(toList)
    })
  }

  createTx(tx: CreateTransactionDto) {
    return this.axios.post("/", tx)
  }

  updateTx(tx: UpdateTransactionDto) {
    if (!tx.id) return Promise.reject("Transaction should contains id ! ")
    return this.axios.put(`/${tx.id}`, tx)
  }

  deleteTxs(txs: List<number>) {
    return this.axios.delete(`/?ids=${txs.stream().join(",")}`).then(() => {})
  }

  clearTxs(txs: List<number>) {
    return this.axios.get(`/clear?ids=${txs.stream().join(",")}`).then(() => {})
  }

  duplicateTxs(txs: List<number>) {
    return this.axios
      .put(`/duplicate?ids=${txs.stream().join(",")}`)
      .then(() => {})
  }

  static get(): TransactionApiService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new TransactionApiService()
    }
    return this.INSTANCE
  }
}
