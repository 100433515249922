import {Cell, Pie, PieChart, ResponsiveContainer, Sector, Text} from "recharts"
import React from "react"
import {NumberUtils} from "@damntools.fr/utils-simple"

export type DefaultPieChartProps = {
  data: Array<any>
  colors?: string[]
  width?: number
  height?: number
}

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"]

const RADIAN = Math.PI / 180
const renderLabelAsPercent = (props: any) => {
  const radius =
    props.innerRadius + (props.outerRadius - props.innerRadius) * 0.5
  const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN)
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN)
  console.log("props", props)
  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > props.cx ? "start" : "end"}
      dominantBaseline="central">
      <tspan x={x} dy="1em">
        {NumberUtils.formatNumber(props.payload.value, 1)}
      </tspan>
      <tspan x={x} dy="1em">
        {`${(props.percent * 100).toFixed(0)}%`}
      </tspan>
    </text>
  )
}

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? "start" : "end"
  console.log(props)
  let name = payload.label || payload.name
  if (name.length > 13) name = name.substring(0, 13) + "..."
  const isPositive = payload.isPositive
  const isNegative = payload.isNegative
  const textColor = isNegative ? "#90B421" : isPositive ? "#D33731" : "white"
  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={"#E8E6E3"}
        fontSize={"small"}>
        {name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {payload.subLabel ? (
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          fontSize={"small"}
          textAnchor={textAnchor}
          fill={textColor}>{payload.subLabel}</text>
      ) : null}
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        fontSize={"small"}
        dy={18}
        textAnchor={textAnchor}
        fill={textColor}>{`${NumberUtils.formatNumber(value, 2)} €`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        fontSize={"small"}
        dy={36}
        textAnchor={textAnchor}
        fill="#999">
        {`${percent.toFixed(1)}%`}
      </text>
    </g>
  )
}

console.log(renderLabelAsPercent)

export class DefaultPieChart extends React.Component<
  DefaultPieChartProps,
  any
> {
  state = {activeIndex: -1}

  // @ts-ignore
  onPieEnter(index: number, p: undefined) {
    this.setState({
      activeIndex: index
    })
  }

  render() {
    let {data, colors} = this.props
    const colorList = colors || COLORS

    return (
      <div style={{width: "700px", height: "300px"}}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              paddingAngle={5}
              activeIndex={this.state.activeIndex}
              activeShape={renderActiveShape}
              cx="50%"
              cy="50%"
              innerRadius={60}
              isAnimationActive={false}
              outerRadius={80}
              fill="#8884d8"
              onMouseEnter={(p: undefined, i) => this.onPieEnter(i, p)}
              dataKey="value">
              {data
                // @ts-ignore
                .map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorList[index % colorList.length]}
                  />
                ))}
            </Pie>
            <Text></Text>
          </PieChart>
        </ResponsiveContainer>
      </div>
    )
  }
}
