import React from "react"
import styles from "./MenuAccount.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {png_menu_edit} from "../../../assets"
import {PriceView} from "../../static"
import {PRICE_POSITIVE_COLOR} from "../../../constants"
import {EnrichedAccount} from "../../../service"
import {openAccountViewPopup} from "../../popin"
import {useNavigate} from "react-router-dom"
import {Strings, tsl} from "../../../i18n"

export type MenuPanelProps = {
  account: EnrichedAccount
}

export function MenuAccount(props: MenuPanelProps) {
  const account = props.account
  const link = `/account/${encodeURI(account.name).replaceAll(/%20/g, "+")}`
  const nav = useNavigate()
  return (
    <div
      className={CssClass.from(styles.MenuAccount)
        .classIf(() => styles.Current, window.location.pathname.startsWith(`/${link}`))
        .get()}>
      <div className={styles.Wrapper}>
        <div className={styles.Name} onClick={() => nav(link)}>
          <span>{account.name}</span>
        </div>
        <div className={styles.Price}>
          <PriceView
            value={account.balance || 0.0}
            positiveColor={PRICE_POSITIVE_COLOR}
          />
        </div>
        <div className={styles.Edit}>
          <span></span>
          <img
            src={png_menu_edit}
            alt={"Edit"}
            title={tsl(Strings.menu.accounts.edit)}
            onClick={() => openAccountViewPopup(account)}
          />
        </div>
      </div>
    </div>
  )
}
