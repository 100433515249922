import {PeerConsumer, ReportPageConsumer, TimeFilter} from "../../provider"
import styles from "./ReportPage.module.scss"
import {List, Optionable, toList} from "@damntools.fr/types"
import {DateTime} from "luxon"
import {DatePicker} from "../../component"
import React from "react"
import {SearchDropDown} from "@damntools.fr/react-inputs"
import {Peer} from "@damntools.fr/wnab-data"
import {getPeerValues} from "../../utils"

export function ReportPageOptions() {
  return (
    <PeerConsumer>
      {({peers}) => (
        <ReportPageConsumer>
          {({
            timeFilter,
            groupBy,
            updateCustomTimeFilter,
            peerFilters,
            updatePeerFilters
          }) => {
            return timeFilter.filter(f => f.label === "custom").isPresent() ||
              groupBy.filter(f => f === "customPeer").isPresent() ||
              groupBy.filter(f => f === "customAccount").isPresent() ||
              groupBy.filter(f => f === "customCategory").isPresent() ? (
              <div className={styles.Options}>
                {timeFilter.filter(f => f.label === "custom").isPresent()
                  ? getCustomTimeFilterOptions(
                      timeFilter,
                      updateCustomTimeFilter
                    )
                  : null}
                {groupBy.filter(f => f === "customPeer").isPresent()
                  ? getCustomPeerSelection(
                      peers,
                      peerFilters,
                      updatePeerFilters
                    )
                  : null}
              </div>
            ) : null
          }}
        </ReportPageConsumer>
      )}
    </PeerConsumer>
  )
}

function getCustomPeerSelection(
  allPeers: List<Peer>,
  filter: List<Peer>,
  updatePeerFilters: (filtered: List<Peer>) => Promise<any>
) {
  const values = getPeerValues(allPeers)
  const selected = values
    .stream()
    .filter(peer =>
      filter
        .stream()
        .findOptional(f => f.id === peer.returnValue.id)
        .isPresent()
    )
    .collect(toList)
  console.log(filter, selected)
  return (
    <div className={styles.CustomTimePeerSelection}>
      <SearchDropDown
        values={values}
        multiple
        dark
        selectedValues={selected}
        maxDropdownHeight={"150px"}
        onChangeMulti={v =>
          updatePeerFilters(
            v
              .stream()
              .map(vd => vd.returnValue)
              .collect(toList)
          )
        }
      />
    </div>
  )
}

function getCustomTimeFilterOptions(
  timeFilter: Optionable<TimeFilter>,
  updateCustomTimeFilter: (start?: DateTime, end?: DateTime) => Promise<any>
) {
  return (
    <div className={styles.CustomTimeFilterOptions}>
      <div>From</div>
      <div>
        <DatePicker
          displayTime
          onChange={d => updateCustomTimeFilter(d.startOf("day"))}
          value={timeFilter.map(f => f.start)}
        />
      </div>
      <div>To</div>
      <div>
        <DatePicker
          displayTime
          onChange={d => updateCustomTimeFilter(undefined, d.endOf("day"))}
          value={timeFilter.map(f => f.end)}
        />
      </div>
    </div>
  )
}
