import {
  consoleTransport,
  Logging,
  nativeConsoleInterceptor
} from "@damntools.fr/logger-simple"

export const initLogging = () => {
  Logging.configure({
    level: "DEBUG",
    useCache: false,
    printEmptyMessage: true,
    transports: [
      consoleTransport({
        pattern: "[%V] %L{-30} %red>%Cend %m"
      })
    ],
    loggers: {
      "damntools.layout": "INFO"
    },
    collectLocationInfo: true,
    nativeConsoleInterceptor
  })
}
