import {ArrayList, Dict, KV, List, toList} from "@damntools.fr/types"
import {
  Account, AccountCtor,
  AccountDto,
  AccountDtoMapper,
  AllAccountBalanceDto,
  CreateAccountDto,
  UpdateAccountDto
} from "@damntools.fr/wnab-data"
import {AxiosWrapper} from "@damntools.fr/http"
import {AxiosService} from "../auth"

export type Balance = {
  account: Account
  balance: number
}

export type EnrichedAccount = Account & {
  balance: number
}

export class AccountApiService {
  static INSTANCE: AccountApiService | null = null
  private readonly axios: AxiosWrapper

  constructor() {
    this.axios = AxiosService.getAuthenticatedInstance().child({
      baseURL: "/resource/account"
    })
  }

  getAccounts(): Promise<List<Account>> {
    return this.axios
      .get("/")
      .then(res => new ArrayList<AccountDto>(res.data))
      .then(res =>
        res
          .stream()
          .map(a => AccountDtoMapper.get().mapTo(a))
          .collect(toList)
      )
  }

  getSplitBalances(): Promise<Dict<number, number>> {
    return this.axios
      .get("/balance/split")
      .then(res => res.data as AllAccountBalanceDto)
      .then(res => {
        const kv = KV.empty<number, number>()
        Object.keys(res.balances).forEach(key => {
          const value = res.balances[key].balance
          let id = parseInt(key)
          kv.put(id, value)
        })
        return kv
      })
  }

  createAccount(account: CreateAccountDto) {
    return this.axios.post("/", account)
  }

  updateAccount(account: UpdateAccountDto) {
    if (!account.id) return Promise.reject("Account should contains id ! ")
    return this.axios.put(`/${account.id}`, account)
  }

  closeAccount(account: AccountCtor) {
    return this.updateAccount({id: account.id, closed: true})
  }

  openAccount(account: AccountCtor) {
    return this.updateAccount({id: account.id, closed: false})
  }

  clearAll(account: AccountCtor) {
    return this.axios.put(`/${account.id}/clear`).then(res => res.data as void)
  }

  reconcile(account: AccountCtor, amount: number) {
    return this.axios
      .put(`/${account.id}/reconcile`, {
        value: amount
      })
      .then(res => res.data as void)
  }

  static get(): AccountApiService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new AccountApiService()
    }
    return this.INSTANCE
  }
}
