import React from "react"
import {RichTransaction} from "@damntools.fr/wnab-data"
import {
  ArrayList,
  List,
  Lists,
  Optionable,
  Optional,
  toList
} from "@damntools.fr/types"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent} from "@damntools.fr/react-utils"

export type SearchType =
  | "account"
  | "peer"
  | "category"
  | "description"
  | "complex"
  | "all"

export const SearchTypeList = Lists.of<SearchType>(
  "account",
  "peer",
  "category",
  "description",
  "complex",
  "all"
)

export type TransactionTableProviderState = {
  selected: List<RichTransaction>
  selectedIds: List<number>
  setSelected: (reset: boolean, tx: RichTransaction) => void
  setSelection: (transactions: List<RichTransaction>) => Promise<any>
  selectAllToggle: (transactions: List<RichTransaction>) => void
  isSelected: (tx: RichTransaction) => boolean
  search: Optionable<string>
  searchType: SearchType
  setSearch: (search: Optionable<string>) => void
  toggleSearchType: () => void
  setSearchType: (type: SearchType) => void
  setFullSearch: (type: SearchType, search: Optionable<string>) => void
}

export const TransactionTableContext = React.createContext(
  {} as TransactionTableProviderState
)

export const TransactionTableConsumer = TransactionTableContext.Consumer

export class TransactionTableProvider extends ReactComponent<
  any,
  TransactionTableProviderState
> {
  private static INSTANCE: TransactionTableProvider | null = null

  constructor(props: any) {
    super(props, Logging.getLogger("TransactionTableOptionsProvider"))
    TransactionTableProvider.INSTANCE = this
  }

  protected getInitialState(): TransactionTableProviderState {
    return {
      selected: new ArrayList(),
      selectedIds: new ArrayList(),
      search: Optional.empty(),
      searchType: "all",
      isSelected: this.isSelected.bind(this),
      toggleSearchType: this.toggleSearchType.bind(this),
      setSearchType: this.setSearchType.bind(this),
      selectAllToggle: this.selectAllToggle.bind(this),
      setSelected: this.setSelected.bind(this),
      setSearch: this.setSearch.bind(this),
      setFullSearch: this.setFullSearch.bind(this),
      setSelection: this.setSelection.bind(this)
    }
  }

  public static reset() {
    if (this.INSTANCE) return this.INSTANCE.setSelection(new ArrayList())
  }

  private isSelected(tx: RichTransaction): boolean {
    return this.state.selectedIds.stream().findIndex(s => s === tx.id) !== -1
  }

  private selectAllToggle(transactions: List<RichTransaction>) {
    if (this.state.selected.hasElements())
      this.setState({selected: this.state.selected.clear()})
    else {
      this.setState({
        selected: transactions.copy(),
        selectedIds: transactions
          .stream()
          .map(tx => tx.id as number)
          .collect(toList)
      })
    }
  }

  private setSelection(transactions: List<RichTransaction>) {
    return this.stater().applyState({
      selected: transactions.copy(),
      selectedIds: transactions
        .stream()
        .map(tx => tx.id as number)
        .collect(toList)
    })
  }

  private setSelected(reset: boolean, tx: RichTransaction): void {
    const index = this.state.selectedIds.stream().findIndex(s => s === tx.id)
    if (reset && index === -1) {
      this.setState({selected: new ArrayList([tx])})
    } else if (reset) {
      this.setState({selected: new ArrayList()})
    } else if (index === -1) {
      this.setState({
        selected: this.state.selected.push(tx),
        selectedIds: this.state.selectedIds.push(tx.id as number)
      })
    } else {
      this.setState({
        selected: this.state.selected.remove(index),
        selectedIds: this.state.selectedIds.remove(index)
      })
    }
  }

  private setSearch(search: Optionable<string>) {
    this.setState({search})
  }

  private setFullSearch(type: SearchType, search: Optionable<string>) {
    this.setState({searchType: type, search})
  }

  private toggleSearchType() {
    const index = SearchTypeList.stream().findIndex(
      t => this.state.searchType === t
    )
    if (index >= SearchTypeList.size() - 1)
      this.setState({searchType: SearchTypeList.get(0) as SearchType})
    else
      return this.setState({
        searchType: SearchTypeList.get(index + 1) as SearchType
      })
  }

  private setSearchType(type: SearchType) {
    if (this.state.searchType === type) this.setState({searchType: "all"})
    else this.setState({searchType: type})
  }

  render() {
    return (
      <TransactionTableContext.Provider value={this.state}>
        {this.props.children}
      </TransactionTableContext.Provider>
    )
  }
}
