import {AlertProvider, Notification, Popin} from "@damntools.fr/react-alert"
import {AccountApiService} from "../../service"
import {AccountProvider, TransactionProvider} from "../../provider"
import {Account} from "@damntools.fr/wnab-data"
import {Strings, tsl} from "../../i18n"

export const openCloseAccountPopin = (account: Account) =>
  AlertProvider.submit(
    Popin.warning(tsl(Strings.popins.closeAccount.title)).SuccessAction(() => {
      AccountApiService.get()
        .closeAccount(account)
        .catch(() =>
          AlertProvider.submit(
            Notification.error().Subtitle(
              tsl(Strings.popins.closeAccount.error)
            )
          )
        )
        .then(() => AccountProvider.refresh())
    }, tsl(Strings.basic.yes))
  )

export const openOpenAccountPopin = (account: Account) =>
  AlertProvider.submit(
    Popin.warning(tsl(Strings.popins.openAccount.title)).SuccessAction(() => {
      AccountApiService.get()
        .openAccount(account)
        .catch(() =>
          AlertProvider.submit(
            Notification.error().Subtitle(tsl(Strings.popins.openAccount.error))
          )
        )
        .then(() => AccountProvider.refresh())
    }, tsl(Strings.basic.yes))
  )

export const openClearAllForAccountPopin = (account: Account) =>
  AlertProvider.submit(
    Popin.warning(tsl(Strings.popins.clearAllForAccount.title)).SuccessAction(
      () => {
        AccountApiService.get()
          .clearAll(account)
          .catch(() =>
            AlertProvider.submit(
              Notification.error().Subtitle(
                tsl(Strings.popins.clearAllForAccount.error)
              )
            )
          )
          .then(() => TransactionProvider.refresh())
          .then(() => AccountProvider.refresh())
      },
      tsl(Strings.basic.yes)
    )
  )
