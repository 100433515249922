import React from "react"
import styles from "./NotificationCartouche.module.scss"
import {png_info} from "../../../assets"
import {
  FlexCell,
  FlexRowBlock,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {Strings, tsl} from "../../../i18n"

export class NotificationCartouche extends React.Component<any, any> {
  render() {
    return (
      <div className={styles.NotificationCartouche}>
        <div>
          <FlexRowBlock>
            <ShrinkFlexCell className={styles.Icon}>
              <span></span>
              <img src={png_info} alt={"Info"} title={"Info"} />
            </ShrinkFlexCell>
            <FlexCell grow={1} shrink={1} className={styles.Text}>
              <span>{tsl(Strings.preview.message)}</span>
            </FlexCell>
          </FlexRowBlock>
        </div>
      </div>
    )
  }
}
