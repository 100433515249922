import React from "react"
import styles from "./MenuPanel.module.scss"
import {MenuEntry} from "../MenuEntry"
import {
  png_add_account,
  png_menu_budget,
  png_menu_chart,
  png_menu_overview
} from "../../../assets"
import {MenuAccounts} from "../MenuAccounts"
import {ToolbarOptions} from "../ToolbarOptions"
import {openAccountViewPopup} from "../../popin"
import {Strings, tsl} from "../../../i18n"
import {BubbleLink, RGBA, StyleSize} from "@damntools.fr/react-utils"
import {useNavigate} from "react-router-dom"

export const MenuPanel = () => {
  const nav = useNavigate()
  return (
    <div className={styles.MenuPanel}>
      <ToolbarOptions onOpenAccountSettings={() => nav("/settings")} />
      <div className={styles.Menus}>
        <div>
          <MenuEntry
            name={tsl(Strings.menu.elements.budget)}
            icon={png_menu_budget}
            link={"/budget"}
          />
          <MenuEntry
            name={tsl(Strings.menu.elements.reports)}
            icon={png_menu_chart}
            link={"/report"}
          />
          <MenuEntry
            name={tsl(Strings.menu.elements.overview)}
            icon={png_menu_overview}
            link={"/overview"}
          />
        </div>
      </div>
      <div className={styles.Accounts}>
        <MenuAccounts />
      </div>
      <div className={styles.AddAccount}>
        <BubbleLink
          title={tsl(Strings.menu.accounts.add)}
          onClick={() => openAccountViewPopup()}
          icon={png_add_account}
          iconSize={StyleSize.px(20)}
          bgColor={RGBA(144, 180, 33, 0.5)}
          effect={"brightness"}
        />
      </div>
    </div>
  )
}
