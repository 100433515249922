import React from "react"
import styles from "../BudgetAssignForm.module.scss"
import {
  DropDownSelector,
  SimpleCalculatorInput,
  ValueDesc,
  VD
} from "@damntools.fr/react-inputs"
import {List, Optionable, Optional, toList} from "@damntools.fr/types"
import {Category} from "@damntools.fr/wnab-data"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {StringUtils} from "@damntools.fr/utils-simple"
import {hasChanged} from "@damntools.fr/react-utils"

export type BudgetAssignFormAvailableProps = {
  text: string
  categories: List<Category>
  sourceCategory: Category
  initialAmount: number
  onValidate: (source: Category, target: Category, amount: number) => void
}
export type BudgetAssignFormAvailableState = {
  category: Optionable<Category>
  amount: Optionable<number>
}

export class BudgetAssignFormAvailable extends React.Component<
  BudgetAssignFormAvailableProps,
  BudgetAssignFormAvailableState
> {
  private readonly defaultTarget: Optionable<Category>

  constructor(
    props:
      | Readonly<BudgetAssignFormAvailableProps>
      | BudgetAssignFormAvailableProps
  ) {
    super(props)
    this.defaultTarget = this.props.categories
      .stream()
      .findOptional(c =>
        StringUtils.equalsIgnoreCase("Ready to assign", c.name)
      )
    this.state = {
      category: this.defaultTarget,
      amount: Optional.nullable(this.props.initialAmount)
    }
  }

  componentDidUpdate(prevProps: Readonly<BudgetAssignFormAvailableProps>) {
    const state = {} as BudgetAssignFormAvailableState
    if (hasChanged(prevProps.initialAmount, this.props.initialAmount))
      state.category = this.defaultTarget
    if (hasChanged(prevProps.initialAmount, this.props.initialAmount))
      state.amount = Optional.of(this.props.initialAmount)
    if (Object.keys(state).length > 0) this.setState(state)
  }

  render() {
    const categories = this.getCategoriesValues(this.props.categories)
    const selectedCategory = this.state.category.isPresent()
      ? categories
          .stream()
          .filter(vd => vd.returnValue.id === this.state.category.get().id)
          .collect(toList)
      : categories
          .stream()
          .filter(vd =>
            this.state.category
              .filter(c => vd.returnValue.id === c.id)
              .isPresent()
          )
          .collect(toList)
    return (
      <div className={styles.BudgetAssignFormAvailable}>
        <div className={styles.BudgetAssignForm}>
          <div>
            <div>
              <div className={styles.AssignFormTitle}>{this.props.text}</div>
              <div className={styles.AssignFormCategories}>
                <DropDownSelector
                  maxHeight={"150px"}
                  showSelection
                  showSearch
                  dark
                  onChange={v => this.onChangeCategory(v)}
                  values={categories}
                  multiple={false}
                  showValuesOnFocus
                  dontShowValuesIfSearchEmpty
                  selectedValues={selectedCategory}
                />
              </div>
              <div className={styles.AssignFormAmount}>
                <SimpleCalculatorInput
                  precision={2}
                  value={this.state.amount.map(VD).mapEmpty(() => VD(0.0))}
                  dark
                  rightAlign
                  focus
                  unit={"€"}
                  hideFormat
                  onChange={v => this.onChangeAmount(v)}
                />
              </div>
              <div className={styles.AssignFormButtons}>
                <div>
                  <span onClick={() => this.onValidate()}>Send</span>
                  <div></div>
                  <span onClick={() => this.onClear()}>Clear</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private onChangeCategory(value: Optionable<ValueDesc<Category>>) {
    this.setState({category: value.map(v => v.returnValue)})
  }

  private getCategoriesValues(categories: List<Category>) {
    return categories
      .copy()
      .stream()
      .map(p => VD(p).Compare(p.id).Display(p.pretty()).Sort(p.pretty()))
      .collect(toList)
  }

  private onChangeAmount(value: Optionable<number>) {
    this.setState({amount: value})
  }

  private onClear() {
    this.setState({
      category: this.defaultTarget,
      amount: Optional.of(this.props.initialAmount)
    })
  }

  private onValidate() {
    if (this.state.amount.isEmpty()) {
      return AlertProvider.submit(
        Notification.error("Amount should be provided")
      )
    }
    if (this.state.category.isEmpty()) {
      return AlertProvider.submit(
        Notification.error("Category should be provided")
      )
    }
    this.props.onValidate(
      this.props.sourceCategory,
      this.state.category.get(),
      this.state.amount.get()
    )
  }
}
