import React, {ReactElement} from "react"
import styles from "./SettingsPage.module.scss"
import {AuthenticationConsumer} from "../../provider"
import {Strings, tsl} from "../../i18n"
import {
  FlexColumnBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {UserProfile} from "@damntools.fr/wnab-data"
import {CheckboxInput, TextInput, VD} from "@damntools.fr/react-inputs"
import {Optional} from "@damntools.fr/types"
import {StyleSize} from "@damntools.fr/react-utils";
import {DateTime} from "luxon";

export const SettingsPage = () => {
  document.title = tsl(Strings.views.settings.title)
  return (
    <AuthenticationConsumer>
      {({authentication}) => {
        return (
          <div className={styles.SettingsPage}>
            <FlexColumnBlock>
              <ShrinkFlexCell className={styles.Title}>
                <h1>{tsl(Strings.views.settings.title)}</h1>
              </ShrinkFlexCell>
              <ShrinkFlexCell className={styles.Toolbar}>
                <div className={styles.Add}></div>
              </ShrinkFlexCell>
              <GrowShrinkFlexCell className={styles.Conten} hideOverflow>
                {authentication
                  .map(a => (
                    <div>
                      <UserSettingsBlock authentication={a} />
                    </div>
                  ))
                  .orElseUndefined()}
              </GrowShrinkFlexCell>
            </FlexColumnBlock>
          </div>
        )
      }}
    </AuthenticationConsumer>
  )
}

export type UserSettingsBlockProps = {
  authentication: UserProfile
}

export const UserSettingsBlock = ({authentication}: UserSettingsBlockProps) => {
  return (
    <div className={styles.UserSettingsBlock}>
      <div className={styles.Rows}>
        {getRow(
            tsl(Strings.views.settings.login),
            <TextInput
                onChange={() => ({})}
                value={Optional.of(authentication.login).map(VD)}
                fontSize={"12px"}
                hideFormat
                readOnly
                dark
            />
        )}
        {getRow(
            tsl(Strings.views.settings.email),
            <TextInput
                onChange={() => ({})}
                value={Optional.of(authentication.email).map(VD)}
                fontSize={"12px"}
                readOnly
                hideFormat
                dark
            />
        )}
        {getRow(
            tsl(Strings.views.settings.importedTransactions),
            <CheckboxInput
                size={StyleSize.px(18)}
                color={"#90b421"}
                onChange={() => ({})}
                checked={authentication.transactionsImported}
                readOnly
                dark
            />
        )}
        {getRow(
            tsl(Strings.views.settings.importedBudgets),
            <CheckboxInput
                size={StyleSize.px(18)}
                onChange={() => ({})}
                color={"#90b421"}
                checked={authentication.budgetsImported}
                readOnly
                dark
            />
        )}
        {getRow(
            tsl(Strings.views.settings.lastLogin),
            <TextInput
                onChange={() => ({})}
                value={Optional.of(authentication.lastLogin.toLocaleString(DateTime.DATETIME_FULL)).map(VD)}
                fontSize={"12px"}
                readOnly
                hideFormat
                dark
            />
        )}
        {getRow(
            tsl(Strings.views.settings.created),
            <TextInput
                onChange={() => ({})}
                value={Optional.of(authentication.createdOn.toLocaleString(DateTime.DATETIME_FULL)).map(VD)}
                fontSize={"12px"}
                readOnly
                hideFormat
                dark
            />
        )}
      </div>
    </div>
  )
}

function getRow(
  label: string,
  input: ReactElement,
  align?: string
): ReactElement {
  return (
    <div className={styles.FormRow}>
      <div className={styles.RowLabel} style={{verticalAlign: align || "top"}}>
        <span>{label}</span>
      </div>
      <div className={styles.RowInput}>{input}</div>
    </div>
  )
}
