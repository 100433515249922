import React, {ReactElement} from "react"
import {ArrayList, List, Optionable, Optional} from "@damntools.fr/types"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent, ReactComponentProps} from "@damntools.fr/react-utils"
import {DateTime} from "luxon"
import {
  IStorage,
  StorageHelper,
  StorageSource
} from "@damntools.fr/local-storage/browser"
import {Category, Peer} from "@damntools.fr/wnab-data"

export type ReportPageViewType =
  | "bar"
  | "line"
  | "pie"
  | "candle"
  | "balance"
  | "overview"

export type ReportPageProviderProps = ReactComponentProps & {
  children: ReactElement[] | ReactElement
  urlViewType: ReportPageViewType
}

export type ReportGroupBy =
  | "parentCategory"
  | "childCategory"
  | "customCategory"
  | "peer"
  | "customPeer"
  | "account"
  | "hotAccount"
  | "coldAccount"
  | "customAccount"
export type SpecificTimeFilter =
  | "today"
  | "thisWeek"
  | "thisMonth"
  | "thisYear"
  | "lastYear"
  | "everything"
  | "custom"

export type TimeFilter = {
  start: DateTime
  end: DateTime
  label?: SpecificTimeFilter
}

export const TimeFilters: {
  [key in SpecificTimeFilter]: (now: DateTime) => TimeFilter
} = {
  today: (now: DateTime) => ({
    start: now.startOf("day"),
    end: now.endOf("day"),
    label: "today"
  }),
  thisWeek: (now: DateTime) => ({
    start: now.startOf("week"),
    end: now.endOf("week"),
    label: "thisWeek"
  }),
  thisMonth: (now: DateTime) => ({
    start: now.startOf("month"),
    end: now.endOf("month"),
    label: "thisMonth"
  }),
  thisYear: (now: DateTime) => ({
    start: now.startOf("year"),
    end: now.endOf("year"),
    label: "thisYear"
  }),
  lastYear: (now: DateTime) => ({
    start: now.minus({year: 1}).startOf("day"),
    end: now.endOf("day"),
    label: "lastYear"
  }),
  everything: (now: DateTime) => ({
    start: now,
    end: now,
    label: "everything"
  }),
  custom: (now: DateTime) => ({
    start: now,
    end: now,
    label: "custom"
  })
}

export type ReportPageProviderState = {
  viewType: ReportPageViewType
  timeFilter: Optionable<TimeFilter>
  groupBy: Optionable<ReportGroupBy>
  peerFilters: List<Peer>
  categoryFilters: List<Category>

  setTimeFilter: (timeFilter?: TimeFilter) => Promise<any>
  updateCustomTimeFilter: (start?: DateTime, end?: DateTime) => Promise<any>
  setGroupBy: (groupBy?: ReportGroupBy) => Promise<any>
  togglePeerFilter: (peer: Peer) => Promise<any>
  updatePeerFilters: (filtered: List<Peer>) => Promise<any>
}

export const ReportPageContext = React.createContext(
  {} as ReportPageProviderState
)

export const ReportPageConsumer = ReportPageContext.Consumer

export class ReportPageProvider extends ReactComponent<
  ReportPageProviderProps,
  ReportPageProviderState
> {
  private readonly storage: IStorage
  private static readonly STORAGE_KEY: string = "reportPageProvider"

  private static readonly DEFAULT_VIEW_TYPE: string = "bar"

  constructor(props: any) {
    super(props, Logging.getLogger("ReportPageProvider"))
    this.storage = StorageHelper.with(StorageSource.WINDOW_LOCAL)
  }

  protected getInitialState(): ReportPageProviderState {
    return {
      viewType: this.props.urlViewType || ReportPageProvider.DEFAULT_VIEW_TYPE,
      categoryFilters: new ArrayList(),
      groupBy: Optional.empty(),
      peerFilters: new ArrayList(),
      timeFilter: Optional.empty(),
      setGroupBy: this.setGroupBy.bind(this),
      setTimeFilter: this.setTimeFilter.bind(this),
      updateCustomTimeFilter: this.updateCustomTimeFilter.bind(this),
      updatePeerFilters: this.updatePeerFilters.bind(this),
      togglePeerFilter: this.togglePeerFilter.bind(this)
    }
  }

  setGroupBy(groupBy?: ReportGroupBy) {
    return this.stater().applyState({groupBy: Optional.nullable(groupBy)})
  }

  setTimeFilter(filter?: TimeFilter) {
    console.log(filter)
    if (
      this.state.timeFilter
        .filter(f => !!f.label && f.label === filter?.label)
        .isPresent()
    ) {
      return this.stater().applyState({
        timeFilter: Optional.empty(),
        showCustomTimeFilter: false
      })
    }
    if (filter && filter.label === "custom") return this.openCustomTimeFilter()
    return this.stater().applyState({
      timeFilter: Optional.nullable(filter),
      showCustomTimeFilter: false
    })
  }

  togglePeerFilter(peer: Peer) {
    const index = this.state.peerFilters
      .stream()
      .findIndex(p => p.id === peer.id)
    if (index > -1) {
      return this.stater().applyState({
        peerFilters: this.state.peerFilters.remove(index)
      })
    } else {
      return this.stater().applyState({
        peerFilters: this.state.peerFilters.push(peer)
      })
    }
  }

  updatePeerFilters(peers: List<Peer>) {
    return this.stater().applyState({peerFilters: peers})
  }

  openCustomTimeFilter() {
    const values = this.state.timeFilter
      .filter(f => !(f.label === "custom"))
      .map(f => ({
        start: f.start,
        end: f.end
      }))
    let now = DateTime.now()
    const customFilter: TimeFilter = {
      start: values.map(v => v.start).orElseReturn(now.startOf("day")),
      end: values.map(v => v.end).orElseReturn(now.endOf("day")),
      label: "custom"
    }
    return this.stater().applyState({
      showCustomTimeFilter: true,
      timeFilter: Optional.of(customFilter)
    })
  }

  updateCustomTimeFilter(start?: DateTime, end?: DateTime) {
    if (this.state.timeFilter.filter(f => f.label === "custom").isPresent()) {
      const filter = this.state.timeFilter.get()
      if (start) {
        filter.start = start
      }
      if (end) {
        filter.end = end
      }
      if (start || end) {
        return this.stater().applyState({timeFilter: Optional.of(filter)})
      }
    }
    return Promise.resolve()
  }

  componentDidMount() {
    this.storage.getOptional(ReportPageProvider.STORAGE_KEY).then<any>(opt => {
      if (opt.isPresent() && !this.props.urlViewType)
        return this.stater().applyState({viewType: opt.get()})
      else if (opt.isEmpty()) {
        return this.storage.set(
          ReportPageProvider.STORAGE_KEY,
          this.props.urlViewType || ReportPageProvider.DEFAULT_VIEW_TYPE
        )
      }
    })
  }

  componentDidUpdate(prevProps: Readonly<ReportPageProviderProps>) {
    if (this.hasChanged(prevProps, "urlViewType") && this.props.urlViewType) {
      this.stater()
        .applyState({viewType: this.props.urlViewType})
        .then(() =>
          this.storage.set(
            ReportPageProvider.STORAGE_KEY,
            this.props.urlViewType
          )
        )
    }
  }

  render() {
    return (
      <ReportPageContext.Provider value={this.state}>
        {this.props.children}
      </ReportPageContext.Provider>
    )
  }
}
