import React from "react"
import styles from "./BudgetPage.module.scss"
import {List, Optionable, Optional, toList} from "@damntools.fr/types"
import {BudgetParentEntry} from "./BudgetParentEntry"
import {BudgetSheet, RichTransaction} from "@damntools.fr/wnab-data"
import {UUID} from "@damntools.fr/identifiers"
import {BudgetAssignForm} from "./BudgetAssignForm"
import {BudgetInfoTable} from "./BudgetInfoTable"
import {BudgetActions} from "./BudgetActions"
import {BudgetTransactions} from "./BudgetTransactions"
import {CssClass, NumberUtils} from "@damntools.fr/utils-simple"
import {
  BudgetConsumer,
  CategoryConsumer,
  TransactionConsumer
} from "../../provider"
import {MonthSelector} from "../../component"
import {BudgetPageProps} from "./BudgetPage.types"
import {Strings, tsl} from "../../i18n";

export class BudgetPage extends React.Component<BudgetPageProps, any> {
  constructor(props: any) {
    super(props)
    this.onKeyPress = this.onKeyPress.bind(this)
  }


  render() {
    return (
      <BudgetConsumer>
        {({sheet}) => {
          return this.getContent(sheet)
        }}
      </BudgetConsumer>
    )
  }

  componentDidMount() {
    document.title = tsl(Strings.views.budget.title)
    document.addEventListener("keyup", this.onKeyPress)
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.onKeyPress)
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.code === "ArrowLeft") {
      e.preventDefault()
      e.stopPropagation()
      this.props.onChangeMonth(this.props.month.minus({month: 1}))
    } else if (e.code === "ArrowRight") {
      e.preventDefault()
      e.stopPropagation()
      this.props.onChangeMonth(this.props.month.plus({month: 1}))
    }
  }

  getContent(sheet: Optionable<BudgetSheet>) {
    return (
      <div className={styles.BudgetPage}>
        <div>
          <div className={styles.Header}>
            <div>
              <div className={styles.MonthSelector}>
                <MonthSelector
                  date={Optional.of(this.props.month)}
                  onChange={value => this.props.onChangeMonth(value)}
                />
              </div>
              <div className={styles.HeaderContent}>
                {sheet
                  .map(s => (
                    <TransactionConsumer>
                      {({budgetNonFutureTransactions}) =>
                        this.getHeaderContent(s, budgetNonFutureTransactions)
                      }
                    </TransactionConsumer>
                  ))
                  .orElseUndefined()}
              </div>
              <div className={styles.AgeOfMoney}>
                {/*<AgeOfMoneyDisplay />*/}
              </div>
            </div>
          </div>
          <div className={styles.Options}></div>
          <div className={styles.Content}>
            <div>
              <div className={styles.LeftPanel}>
                {sheet
                  .map(s => (
                    <CategoryConsumer>
                      {({subCategories, parentCategories}) => {
                        return (
                          <div>
                            {parentCategories
                              .stream()
                              .filter(c => c.name.toLowerCase() !== "inflow")
                              .sortWith("name")
                              .map(c => {
                                const children = subCategories
                                  .stream()
                                  .filter(cc => cc.parent?.id === c.id)
                                  .collect(toList)
                                return (
                                  <BudgetParentEntry
                                    key={c.id}
                                    budgetSheet={s}
                                    category={c}
                                    children={children}
                                  />
                                )
                              })
                              .collectArray()}
                          </div>
                        )
                      }}
                    </CategoryConsumer>
                  ))
                  .orElseUndefined()}
              </div>
              <div className={styles.RightPanel}>
                <div>
                  <div className={styles.PanelInfo}>
                    <h1>Info</h1>

                    {sheet
                      .map(s => (
                        <BudgetInfoTable key={UUID.random()} budgetSheet={s} />
                      ))
                      .orElseUndefined()}
                  </div>
                  <div className={styles.PanelActions}>
                    <h1>Actions</h1>

                    {sheet
                      .map(s => (
                        <BudgetActions key={UUID.random()} budgetSheet={s} />
                      ))
                      .orElseUndefined()}
                  </div>
                  <div className={styles.PanelOther}>
                    <h1>Other</h1>
                    <BudgetAssignForm
                      key={UUID.random()}
                      month={this.props.month}
                    />
                  </div>
                  <div className={styles.PanelTransactions}>
                    <h1>Transactions</h1>

                    {sheet
                      .map(s => (
                        <BudgetTransactions
                          key={UUID.random()}
                          budgetSheet={s}
                        />
                      ))
                      .orElseUndefined()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private getHeaderContent(
    budgetSheet: BudgetSheet,
    budgetNonFutureTransactions: List<RichTransaction>
  ) {
    const available = budgetNonFutureTransactions
      .stream()
      .map(tx => tx.cashFlow)
      .reduce((o, c) => o + c, 0)
    const budgeted = budgetSheet.categoryBudgets
      .values()
      .stream()
      .map(e => e.budget.available)
      .reduce((o, c) => o + c, 0)
    const ready = available - budgeted
    return (
      <div>
        <div className={styles.InfluxTile}>
          <div className={styles.InfluxTitle}>
            <div>Ready to assign</div>
          </div>
          <div
            className={CssClass.from(styles.InfluxValue)
              .classIf(styles.Positive, ready > 0.009)
              .classIf(styles.Negative, ready < -0.009)
              .get()}>
            <div>
              <span>{NumberUtils.formatNumber(ready, 2)} €</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
