import {VD} from "@damntools.fr/react-inputs"
import {
  Account,
  AccountType,
  Category,
  Peer,
  PeerType,
  RecurringTransactionStep,
  TransactionStatus
} from "@damntools.fr/wnab-data"
import {StringUtils} from "@damntools.fr/utils-simple"
import {List, toList} from "@damntools.fr/types"
import {
  png_cash,
  png_coin,
  png_credit_card,
  png_menu_chart,
  png_peer_entity,
  png_peer_person,
  png_savings
} from "../assets"
import {Strings, tsl} from "../i18n"

export const getStatusValues = () => {
  return [
    VD(TransactionStatus.CLEARED)
      .Display(tsl(Strings.resources.transaction.status.cleared))
      .Color("green"),
    VD(TransactionStatus.UNCLEARED)
      .Display(tsl(Strings.resources.transaction.status.uncleared))
      .Color("grey")
  ]
}

export const getRecurringValueTranslation = (
  step: RecurringTransactionStep
) => {
  if (RecurringTransactionStep.DAILY.equals(step))
    return tsl(Strings.resources.transaction.recurring.daily)
  else if (RecurringTransactionStep.WEEKLY.equals(step))
    return tsl(Strings.resources.transaction.recurring.weekly)
  else if (RecurringTransactionStep.EVERY_MONDAY.equals(step))
    return tsl(Strings.resources.transaction.recurring.everyMonday)
  else if (RecurringTransactionStep.EVERY_SATURDAY.equals(step))
    return tsl(Strings.resources.transaction.recurring.everySaturday)
  else if (RecurringTransactionStep.MONTHLY.equals(step))
    return tsl(Strings.resources.transaction.recurring.monthly)
  else if (RecurringTransactionStep.EVERY_FIRST_OF_MONTH.equals(step))
    return tsl(Strings.resources.transaction.recurring.everyFirstOfMonth)
  else if (RecurringTransactionStep.ONCE_EVERY_TWO_MONTH.equals(step))
    return tsl(Strings.resources.transaction.recurring.onceEveryTwoMonth)
  else if (RecurringTransactionStep.ONCE_EVERY_THREE_MONTH.equals(step))
    return tsl(Strings.resources.transaction.recurring.onceEveryThreeMonth)
  else if (RecurringTransactionStep.ONCE_EVERY_SIX_MONTH.equals(step))
    return tsl(Strings.resources.transaction.recurring.onceEverySixMonth)
  else if (RecurringTransactionStep.ANNUALLY.equals(step))
    return tsl(Strings.resources.transaction.recurring.annually)
  else return ""
}

export const getRecurringValues = () => {
  return RecurringTransactionStep.all<RecurringTransactionStep>()
    .stream()
    .map(s =>
      VD(s)
        .Display(StringUtils.firstToUpper(getRecurringValueTranslation(s)))
        .Sort(s.index())
    )
    .collect(toList)
}

export const getPeerValues = (peers: List<Peer>) => {
  return peers
    .copy()
    .stream()
    .map(p =>
      VD(p)
        .Compare(p.id)
        .Display(p.name)
        .Sort(p.name)
        .Icon(getPeerTypeIcon(p.type))
    )
    .collect(toList)
}

export const getAccountValues = (accounts: List<Account>) => {
  return accounts
    .copy()
    .stream()
    .map(a =>
      VD(a)
        .Compare(a.id)
        .Display(a.name)
        .Sort(a.name)
        .Icon(getAccountTypeIcon(a.type))
    )
    .collect(toList)
}

export const getCategoryValues = (categories: List<Category>) => {
  return categories
    .copy()
    .stream()
    .filter(c => c.name.toLowerCase() !== "ready to assign")
    .map(p => VD(p).Compare(p.id).Display(p.pretty()).Sort(p.pretty()))
    .collect(toList)
}

export const getPeerTypeValues = () => {
  return [
    VD(PeerType.PERSON).Display("Person").Icon(png_peer_person),
    VD(PeerType.ENTITY).Display("Entity").Icon(png_peer_entity)
  ]
}

export const getAccountTypeValues = () => {
  return [
    VD(AccountType.CASH).Display("Cash").Icon(png_cash),
    VD(AccountType.DAILY_USAGE).Display("Daily usage").Icon(png_credit_card),
    VD(AccountType.SAVINGS).Display("Savings").Icon(png_savings),
    VD(AccountType.INVESTMENT).Display("Investment").Icon(png_coin)
  ]
}

export const getPeerTypeIcon = (type: PeerType): string => {
  switch (type.key()) {
    case PeerType.ENTITY.key(): {
      return png_peer_entity
    }
    case PeerType.PERSON.key(): {
      return png_peer_person
    }
  }
  return undefined as any
}

export const getAccountTypeIcon = (type: AccountType): string => {
  if (AccountType.CASH.equals(type)) return png_cash
  else if (AccountType.INVESTMENT.equals(type)) return png_menu_chart
  else if (AccountType.DAILY_USAGE.equals(type)) return png_credit_card
  else if (AccountType.SAVINGS.equals(type)) return png_savings
  return undefined as any
}
