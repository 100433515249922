import React from "react"
import styles from "./AccountView.module.scss"
import {
  AccountConsumer,
  TransactionConsumer,
  TransactionTableProvider
} from "../../provider"
import {
  AccountInfoCartouche,
  BalanceCartouche,
  LoaderDiv,
  TransactionTable
} from "../../component"
import {useParams} from "react-router"
import {
  FlexRowBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {Account} from "@damntools.fr/wnab-data"
import {hasChanged} from "@damntools.fr/react-utils"

export const AccountView = () => {
  const {id} = useParams()
  if (!id) return <div>Bad call !</div>
  return (
    <AccountConsumer>
      {({getAccountByName}) => {
        const viewedAccount = getAccountByName(
          decodeURI(id.replaceAll(/\+/g, "%20"))
        )
        if (viewedAccount.isEmpty()) return <LoaderDiv />
        const account = viewedAccount.get()
        document.title = account.name
        return <SpecificAccountView account={account} />
      }}
    </AccountConsumer>
  )
}

export type SpecificAccountViewProps = {
  account: Account
}

export class SpecificAccountView extends React.Component<
  SpecificAccountViewProps,
  any
> {
  componentDidUpdate(prevProps: Readonly<any>) {
    if (hasChanged(prevProps.account.id, this.props.account.id)) {
      return TransactionTableProvider.reset()
    }
  }

  render() {
    return (
      <TransactionConsumer>
        {({txByAccount}) => {
          const transactionList = txByAccount(this.props.account)
          return (
            <div className={styles.AccountView}>
              <FlexRowBlock>
                <ShrinkFlexCell>
                  <AccountInfoCartouche
                    account={this.props.account}
                    showButtons={true}
                  />
                </ShrinkFlexCell>
                <ShrinkFlexCell>
                  <BalanceCartouche transactions={transactionList} />
                </ShrinkFlexCell>
                <GrowShrinkFlexCell hideOverflow>
                  <TransactionTableProvider>
                    <TransactionTable
                      data={transactionList}
                      currentAccount={this.props.account}
                      hideCategory={this.props.account.type.isCold()}
                    />
                  </TransactionTableProvider>
                </GrowShrinkFlexCell>
              </FlexRowBlock>
            </div>
          )
        }}
      </TransactionConsumer>
    )
  }
}
