import React from "react"
import styles from "./ToolbarOptions.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {
  png_bank,
  png_category,
  png_flag,
  png_peer_logo,
  png_settings
} from "../../../assets"
import {Strings, tsl} from "../../../i18n"
import {AuthenticationConsumer} from "../../../provider"
import {BubbleLink, IconLink, RGBA, SimpleLink} from "@damntools.fr/react-utils"
import {AuthenticationService} from "../../../service";

export type ToolbarOptionsProps = {
  onOpenAccountSettings: () => void
}

export type ToolbarOptionsState = {
  expandToolbar: boolean
}

export class ToolbarOptions extends React.Component<
  ToolbarOptionsProps,
  ToolbarOptionsState
> {
  constructor(props: any) {
    super(props)
    this.state = {
      expandToolbar: false
    }
  }

  render() {
    return (
      <div className={styles.ToolbarOptions}>
        <div className={styles.ToolbarTitle}>
          <div>
            <AuthenticationConsumer>
              {({authentication}) => (
                <span>
                  {tsl(Strings.menu.description.greeting)}{" "}
                  {authentication.map(a => a.login).orElseReturn("")}
                </span>
              )}
            </AuthenticationConsumer>
          </div>
          <div>
            <span
              title={tsl(
                !this.state.expandToolbar
                  ? Strings.menu.toolbar.expand
                  : Strings.menu.toolbar.hide
              )}
              onClick={() =>
                this.setState({expandToolbar: !this.state.expandToolbar})
              }>
              {!this.state.expandToolbar ? "▼" : "▲"}
            </span>
          </div>
        </div>
        <div className={styles.ToolbarExpand}>
          {this.state.expandToolbar ? this.getToolbar() : null}
        </div>
      </div>
    )
  }

  getToolbar() {
    return (
      <div>
        <div
          className={CssClass.from(
            styles.EntityManagement,
            styles.ToolbarPart
          ).get()}>
          <div className={styles.AuthButtons} >
            <BubbleLink
                className={styles.OpenSettings}
                title={tsl(Strings.authenticated.openAccountSettings)}
                onClick={() => this.props.onOpenAccountSettings()}
                bgColor={RGBA(36, 133, 175)}
            />
            <BubbleLink
                className={styles.Logout}
                title={tsl(Strings.authenticated.logout)}
                onClick={() => AuthenticationService.get().logout()}
                bgColor={RGBA(211, 55, 49)}
            />
          </div>
          <div>{tsl(Strings.menu.toolbar.manage)}</div>
          <div className={styles.ManageTiles}>
            <IconLink
              link={"/category"}
              title={tsl(Strings.resources.category.plural)}
              icon={png_category}
              color={RGBA(144, 180, 33)}
            />
            <IconLink
              link={"/peer"}
              title={tsl(Strings.resources.peer.plural)}
              icon={png_peer_logo}
              color={RGBA(196, 165, 45)}
            />
            <IconLink
              link={"/account"}
              title={tsl(Strings.resources.account.plural)}
              icon={png_bank}
              color={RGBA(211, 55, 49)}
            />
            <IconLink
              link={"/flag"}
              title={tsl(Strings.resources.flag.plural)}
              icon={png_flag}
              color={RGBA(183, 100, 33)}
            />
            <IconLink
              link={"/options"}
              title={tsl(Strings.menu.options)}
              icon={png_settings}
              color={RGBA(36, 133, 175)}
            />
          </div>
        </div>
        <div className={styles.ToolbarPart}>
          <p>
            <strong>{tsl(Strings.title)}</strong>{" "}
            {tsl(Strings.menu.description.title)}{" "}
            <strong>{tsl(Strings.longTitle)}</strong>{" "}
          </p>
          <p>{tsl(Strings.menu.description.text)}</p>
        </div>
        <div className={CssClass.from(styles.ToolbarPart, styles.Links).get()}>
          <SimpleLink
            link={"/bugreport"}
            title={tsl(Strings.menu.report)}
            className={styles.Report}
          />
          <SimpleLink
            link={"/donate"}
            title={tsl(Strings.menu.donate)}
            className={styles.Donate}
          />
        </div>
      </div>
    )
  }
}
