import React from "react"
import {List, Lists, toList} from "@damntools.fr/types"
import {
  Account,
  RichTransaction,
  TransactionFlag
} from "@damntools.fr/wnab-data"
import {DateTime} from "luxon"
import {
  isFromBudgetAccount,
  isNotFuture,
  TransactionApiService,
  TransactionFlagApiService
} from "../service"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent} from "@damntools.fr/react-utils"

export type TransactionProviderState = {
  transactions: List<RichTransaction>
  budgetNonFutureTransactions: List<RichTransaction>
  flags: List<TransactionFlag>
  txByAccount: (account: Account) => List<RichTransaction>
  refresh: () => void
}

export const TransactionContext = React.createContext(
  {} as TransactionProviderState
)

const newMonthFromToday = DateTime.now()
  .set({hour: 0, minute: 0, second: 0, millisecond: 0})
  .plus({month: 1})

export const isBeforeNextMonth = (v: RichTransaction): v is RichTransaction =>
  v.date.toMillis() < newMonthFromToday.toMillis()

export const TransactionConsumer = TransactionContext.Consumer

export const filterTodayAndBefore = (
  v: RichTransaction
): v is RichTransaction =>
  v.date.toMillis() <
  DateTime.now()
    .set({hour: 0, minute: 0, second: 0, millisecond: 0})
    .plus({day: 1})
    .toMillis()

export class TransactionProvider extends ReactComponent<
  any,
  TransactionProviderState
> {
  private static INSTANCE: TransactionProvider | null = null

  constructor(props: any) {
    super(props, Logging.getLogger("AccountProvider"))
    TransactionProvider.INSTANCE = this
  }

  protected getInitialState(): TransactionProviderState {
    return {
      txByAccount: (account: Account) => {
        return this.state.transactions
          .stream()
          .filter(
            (tx): tx is RichTransaction =>
              tx.account?.id === account.id && isBeforeNextMonth(tx)
          )
          .collect(toList)
      },
      transactions: Lists.empty(),
      budgetNonFutureTransactions: Lists.empty(),
      flags: Lists.empty(),
      refresh: () => this.prepareData()
    }
  }

  static refresh() {
    if (this.INSTANCE) return this.INSTANCE.state.refresh()
  }

  componentDidMount() {
    void this.prepareData()
  }

  prepareData() {
    return TransactionApiService.get()
      .getRichTxs()
      .then(txs =>
        TransactionFlagApiService.get()
          .getFlags()
          .then(flags => {
            const budgetNonFutureTransactions = txs
              .stream()
              .filter(v => isNotFuture(v) && isFromBudgetAccount(v))
              .collect(toList)
            return this.stater().applyState({
              transactions: txs,
              budgetNonFutureTransactions,
              flags
            })
          })
      )
  }

  render() {
    return (
      <TransactionContext.Provider value={this.state}>
        {this.props.children}
      </TransactionContext.Provider>
    )
  }
}
