import React from "react"
import styles from "./CategoryView.module.scss"
import {Category} from "@damntools.fr/wnab-data"
import {List} from "@damntools.fr/types"
import {CategorySubEntry} from "./CategorySubEntry"
import {
  confirmCategoryDeletion,
  HiddenToggle,
  openCategoryEditPopup
} from "../../component"
import {png_add_account, png_delete2, png_menu_edit} from "../../assets"
import {Strings, tsl} from "../../i18n"
import {CategoryApiService} from "../../service"
import {CategoryProvider} from "../../provider"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"

export type CategoryParentEntryProps = {
  category: Category
  subCategories: List<Category>
}

export class CategoryParentEntry extends React.Component<
  CategoryParentEntryProps,
  any
> {
  render() {
    const category = this.props.category
    return (
      <div className={styles.CategoryParentEntry}>
        <div className={styles.ParentZone}>
          <div className={styles.ParentName}>{category.name}</div>
          <div className={styles.ParentHidden}>
            <HiddenToggle
              hidden={category.hidden}
              onChange={v => this.onChangeHidden(v)}
            />
          </div>
          <div className={styles.ParentButton}>
            <div onClick={() => openCategoryEditPopup(undefined, category)}>
              <span></span>
              <img
                src={png_add_account}
                alt={"add"}
                title={tsl(Strings.views.category.addChild)}
              />
            </div>
          </div>
          <div className={styles.ParentButton}>
            <div onClick={() => openCategoryEditPopup(category)}>
              <span></span>
              <img
                src={png_menu_edit}
                alt={"edit"}
                title={tsl(Strings.views.category.edit)}
              />
            </div>
          </div>
          <div className={styles.ParentButton + " " + styles.Delete}>
            <div onClick={() => confirmCategoryDeletion(category)}>
              <span></span>
              <img
                src={png_delete2}
                alt={"delete"}
                title={tsl(Strings.views.category.delete)}
              />
            </div>
          </div>
        </div>
        <div>
          <div className={styles.SubCategories}>
            {this.props.subCategories
              .copy()
              .sortWith("name")
              .stream()
              .map(cat => <CategorySubEntry key={cat.id} category={cat} />)
              .collectArray()}
          </div>
        </div>
      </div>
    )
  }

  onChangeHidden(value: boolean) {
    const category = new Category(this.props.category)
    category.hidden = value
    CategoryApiService.get()
      .update(category)
      .then(() => CategoryProvider.refresh())
      .catch(err => {
        console.error("err", err)
        AlertProvider.submit(
          Notification.error("Could not update hidden value !").Subtitle(
            err?.response?.data?.reason || ""
          )
        )
      })
  }
}
