import React from "react"
import {SimpleCalculatorInput, VD} from "@damntools.fr/react-inputs"
import {Optionable, Optional} from "@damntools.fr/types"
import {Account} from "@damntools.fr/wnab-data"
import {AccountApiService} from "../../../service"
import {AccountProvider, TransactionProvider} from "../../../provider"
import {PopinForm, PopinFormProps, PopinFormState} from "../PopinForm"
import {Validator} from "@damntools.fr/validators"
import {Strings, tsl} from "../../../i18n"

export type AccountReconcileViewProps = PopinFormProps & {
  account: Account
}

export type AccountReconcileViewState = PopinFormState & {
  currentAmount: Optionable<number>
}

export const openAccountReconcilePopup = (account: Account) =>
  PopinForm.open<AccountReconcileViewProps, AccountReconcileView>(
    {
      account,
      title: tsl(Strings.popins.reconcileAccount.title)
    },
    AccountReconcileView
  )

export class AccountReconcileView extends PopinForm<
  AccountReconcileViewProps,
  AccountReconcileViewState,
  any
> {
  constructor(props: AccountReconcileViewProps) {
    super(props, "AccountReconcileView")
  }

  protected getInitialState(): AccountReconcileViewState {
    return {
      currentAmount: Optional.empty()
    }
  }

  protected getValidator(): Validator<AccountReconcileViewState> {
    return new Validator<AccountReconcileViewState>()
  }

  getForm() {
    return this.getRow(
      tsl(Strings.popins.reconcileAccount.amount),
      <SimpleCalculatorInput
        dark
        precision={2}
        onChange={v => this.onChangeOptional("currentAmount", v)}
        value={this.state.currentAmount.map(VD)}
        focus
        required={false}
        unit={"€"}
      />
    )
  }

  protected onSuccess(model: AccountReconcileViewState) {
    return  AccountApiService.get()
      .reconcile(this.props.account, model.currentAmount.get())
      .then(() => TransactionProvider.refresh())
      .then(() => AccountProvider.refresh())
      .catch(err =>
        this.processError(err, tsl(Strings.popins.reconcileAccount.error))
      )
  }
}
