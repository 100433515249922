import React from "react"
import {Optionable, Optional} from "@damntools.fr/types"
import {UserProfile} from "@damntools.fr/wnab-data"
import {AuthenticationService} from "../service"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent} from "@damntools.fr/react-utils"

export const AuthenticationContext = React.createContext(
  {} as AuthenticationProviderState
)

export const AuthenticationConsumer = AuthenticationContext.Consumer

export type AuthenticationProviderState = {
  authentication: Optionable<UserProfile>
  pending: boolean
}

export class AuthenticationProvider extends ReactComponent<
  any,
  AuthenticationProviderState
> {
  constructor(props: any) {
    super(props, Logging.getLogger("AuthenticationProvider"))
  }

  componentDidMount() {
    return this.prepareData()
  }

  protected getInitialState() {
    return {
      authentication: Optional.empty<UserProfile>(),
      pending: false
    }
  }

  private prepareData() {
    return this.stater()
      .applyState({pending: true})
      .then(() => {
        return this.retrieveUser()
      })
      .then(user => {
        return this.stater().applyState({
          authentication: Optional.of(user),
          pending: false
        })
      })
      .catch(() => {
        return this.stater().applyState({
          authentication: Optional.empty(),
          pending: false
        })
      })
  }

  private retrieveUser(): Promise<UserProfile> {
    return AuthenticationService.get().getUserProfile()
  }

  render() {
    return (
      <AuthenticationContext.Provider value={this.state}>
        {this.props.children}
      </AuthenticationContext.Provider>
    )
  }
}
