import {List, toList} from "@damntools.fr/types"
import {RichTransaction, Transaction} from "@damntools.fr/wnab-data"
import {Strings, tsl} from "../../i18n"
import {AlertProvider, Notification, Popin} from "@damntools.fr/react-alert"
import {AccountProvider, TransactionProvider} from "../../provider"
import React from "react"
import {NumberUtils} from "@damntools.fr/utils-simple"
import {TransactionApiService} from "../../service"

function getConfirmMessage(message: string, count: number) {
  return tsl(message)
    .replace(
      "{{type}}",
      tsl(
        count > 1
          ? Strings.resources.transaction.plural
          : Strings.resources.transaction.single
      ).toLowerCase()
    )
    .replace("{{count}}", NumberUtils.formatNumber(count, 0))
}

function getSuccessMessage(message: string, count: number) {
  return tsl(message)
    .replace(
      "{{type}}",
      tsl(
        count > 1
          ? Strings.resources.transaction.plural
          : Strings.resources.transaction.single
      ).toLowerCase()
    )
    .replace("{{count}}", NumberUtils.formatNumber(count, 0))
}

function getIds(txs: List<RichTransaction | Transaction>): List<number> {
  return txs
    .stream()
    .map(tx => tx.id)
    .collect(toList)
}

function processError(message: string, count: number, err: Error) {
  const title = tsl(message).replace(
    "{{type}}",
    tsl(
      count > 1
        ? Strings.resources.transaction.plural
        : Strings.resources.transaction.single
    )
  )
  console.error(title, err)
  const error = err as any
  return AlertProvider.submit(
    Notification.error(title).Subtitle(error?.response?.data?.reason || "")
  )
}

function open(
  confirm: string,
  full: string,
  success: string,
  error: string,
  txs: List<RichTransaction | Transaction>,
  action: () => Promise<any>
) {
  const number = txs.size()
  return AlertProvider.submit(
    Popin.warning(getConfirmMessage(confirm, number))
      .Content(
        <div>
          <p>{tsl(full)}</p>
        </div>
      )
      .OnSuccess(() => {
        action()
          .then(() =>
            AlertProvider.submit(
              Notification.success(getSuccessMessage(success, number))
            )
          )
          .then(() => TransactionProvider.refresh())
          .then(() => AccountProvider.refresh())
          .catch(err => processError(error, number, err))
      })
  )
}

export const confirmTxDeletion = (txs: List<RichTransaction | Transaction>) => {
  return open(
    Strings.popins.transactions.delete.confirmMessage,
    Strings.popins.transactions.delete.fullMessage,
    Strings.popins.transactions.delete.successMessage,
    Strings.popins.transactions.delete.errorMessage,
    txs,
    () => TransactionApiService.get().deleteTxs(getIds(txs))
  )
}


export const confirmTxDuplication = (txs: List<RichTransaction | Transaction>) => {
  return open(
    Strings.popins.transactions.duplicate.confirmMessage,
    Strings.popins.transactions.duplicate.fullMessage,
    Strings.popins.transactions.duplicate.successMessage,
    Strings.popins.transactions.duplicate.errorMessage,
    txs,
    () => TransactionApiService.get().duplicateTxs(getIds(txs))
  )
}


export const confirmTxClear = (txs: List<RichTransaction | Transaction>) => {
  return open(
    Strings.popins.transactions.clear.confirmMessage,
    Strings.popins.transactions.clear.fullMessage,
    Strings.popins.transactions.clear.successMessage,
    Strings.popins.transactions.clear.errorMessage,
    txs,
    () => TransactionApiService.get().clearTxs(getIds(txs))
  )
}
