import React from "react"
import {TextInput, ValueDesc, VD} from "@damntools.fr/react-inputs"
import {
  ArrayList,
  List,
  Optionable,
  Optional,
  toList
} from "@damntools.fr/types"
import {Category, CreateCategoryDto} from "@damntools.fr/wnab-data"
import {CategoryApiService} from "../../../service"
import {CategoryConsumer, CategoryProvider} from "../../../provider"
import {PopinForm} from "../PopinForm"
import {Check, Checks, Validator} from "@damntools.fr/validators"
import {
  CategoryEditViewProps,
  CategoryEditViewState
} from "./CategoryEditView.props"
import {HiddenToggle} from "../../static"
import {Strings, tsl} from "../../../i18n"

export const openCategoryEditPopup = (category?: Category, parent?: Category) =>
  PopinForm.open<CategoryEditViewProps, CategoryEditView>(
    {
      parent,
      category,
      title: category
        ? `${tsl(Strings.resources.category.update)} : ${category.name}`
        : tsl(Strings.resources.category.create)
    },
    CategoryEditView
  )

export class CategoryEditView extends PopinForm<
  CategoryEditViewProps,
  CategoryEditViewState,
  CategoryEditViewState
> {
  constructor(props: CategoryEditViewProps) {
    super(props, "CategoryEditView")
  }

  protected getForm(): React.ReactElement<
    any,
    string | React.JSXElementConstructor<any>
  > {
    return (
      <CategoryConsumer>
        {({parentCategories}) => {
          const parentValues = this.getParentValues(parentCategories)
          const selectedValue = this.getSelectedParent(parentValues)
          return (
            <div>
              {this.props.parent
                ? this.getRow(
                    tsl(Strings.popins.categoryEdit.fields.parent),
                    this.getDropdown(
                      v => this.onChangeParent(v),
                      parentValues,
                      selectedValue
                        .map(v => new ArrayList([v]))
                        .orElseReturn(new ArrayList<ValueDesc<Category>>())
                    )
                  )
                : null}
              {this.getRow(
                tsl(Strings.popins.categoryEdit.fields.name),
                <TextInput
                  dark={true}
                  onChange={v => this.onChangeOptional("name", v)}
                  hideFormat={true}
                  value={this.state.name.map(VD)}
                />
              )}
              {this.getRow(
                tsl(Strings.popins.categoryEdit.fields.hidden),
                <HiddenToggle
                  hidden={this.state.hidden.orElseReturn(false)}
                  onChange={v => this.onChangeOptional("hidden", v)}
                />
              )}
            </div>
          )
        }}
      </CategoryConsumer>
    )
  }

  protected getInitialState(): CategoryEditViewState {
    if (this.props.category) {
      const category = this.props.category
      return {
        hidden: Optional.of(category.hidden),
        name: Optional.of(category.name),
        parent: Optional.nullable(this.props.parent)
      }
    } else {
      return {
        hidden: Optional.of(false),
        name: Optional.empty(),
        parent: Optional.nullable(this.props.parent)
      }
    }
  }

  protected getValidator(): Validator<CategoryEditViewState> {
    const validator = new Validator<CategoryEditViewState>().addCheck(
      Check.field("name").with(
        Check.optional()
          .provided(tsl(Strings.popins.categoryEdit.error.emptyName))
          .valueCheck(
            Checks.String.matches(/^[a-zA-Z0-9.\s,_-]+$/g),
            tsl(Strings.popins.categoryEdit.error.badNamePattern)
          )
      )
    )
    if (this.props.category?.parent) {
      validator.addCheck(
        Check.field("parent").with(
          Check.optional().provided(
            tsl(Strings.popins.categoryEdit.error.parentShouldBeProvided)
          )
        )
      )
    }
    return validator
  }

  private onChangeParent(value: Optionable<ValueDesc<Category>>) {
    this.setState({parent: value.map(v => v.returnValue)}, () => this.store())
  }

  private getParentValues(
    categories: List<Category>
  ): List<ValueDesc<Category>> {
    return categories
      .copy()
      .stream()
      .map(p => VD(p).Compare(p.id).Display(p.pretty()).Sort(p.pretty()))
      .collect(toList)
  }

  private getSelectedParent(
    parentValues: List<ValueDesc<Category>>
  ): Optionable<ValueDesc<Category>> {
    return parentValues
      .stream()
      .findOptional(v => this.props.parent?.id === v.returnValue.id)
  }

  protected onSuccess(model: CategoryEditViewState) {
    const category: CreateCategoryDto = {
      hidden: model.hidden.orElseReturn(false),
      name: model.name.get(),
      parentId: model.parent.map(c => c.id).orElseUndefined()
    }
    if (this.props.category) {
      const id = this.props.category.id
      return CategoryApiService.get()
        .update({...category, id})
        .then(() => CategoryProvider.refresh())
        .catch(err =>
          this.processError(
            err,
            tsl(Strings.popins.categoryEdit.error.updateError)
          )
        )
    } else {
      return CategoryApiService.get()
        .create(category)
        .then(() => CategoryProvider.refresh())
        .catch(err =>
          this.processError(
            err,
            tsl(Strings.popins.categoryEdit.error.createError)
          )
        )
    }
  }
}
