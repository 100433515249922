import React from "react"
import styles from "./DatePicker.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import ReactDatePicker from "react-datepicker"
import {DateTime} from "luxon"
import {Optionable} from "@damntools.fr/types"

export type DateTimePickerProps = {
  onChange: (date: DateTime) => void
  value: Optionable<DateTime>
  displayTime?: boolean
}

export function DatePicker(props: DateTimePickerProps) {
  return (
    <div className={CssClass.from(styles.DateTimePicker).get()}>
      <div>
        <div>
          <ReactDatePicker
            dateFormat={
              props.displayTime ? "MMMM dd, yyyy HH:mm" : "MMMM dd, yyyy"
            }
            selected={props.value.map(d => d.toJSDate()).orElseUndefined()}
            popperClassName={styles.Popper}
            wrapperClassName={styles.Wrapper}
            calendarClassName={styles.Calendar}
            className={styles.DatePicker}
            onChange={d => props.onChange(DateTime.fromJSDate(d as Date))}
          />
        </div>
      </div>
    </div>
  )
}

