import i18n from "i18next"
import {initReactI18next} from "react-i18next"
import {I18N} from "../i18n"

export const initI18next = () =>
  i18n.use(initReactI18next).init({
    resources: I18N.get().prepare(),
    lng: "en",
    fallbackLng: "en",

    interpolation: {
      escapeValue: false
    }
  })
