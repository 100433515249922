import styles from "./TransactionTable.module.scss"
import {RichTransaction, TransactionStatus} from "@damntools.fr/wnab-data"
import React from "react"

export type TransactionStatusCellProps = {
  tx: RichTransaction
  isFutureTx: boolean
  onClick: () => void
}

export const TransactionStatusCell = ({
  tx,
  isFutureTx,
  onClick
}: TransactionStatusCellProps) => {
  return (
    <div className={styles.StatusCheckbox}>
      {!isFutureTx ? (
        <span onClick={() => onClick()}>
          {TransactionStatus.UNCLEARED.equals(tx.status) ? (
            <span style={{color: "rgb(187,149,70)"}} title={"Uncleared"}>
              &#10007;
            </span>
          ) : (
            <span style={{color: "rgb(139, 175, 115)"}} title={"Cleared"}>
              &#10003;
            </span>
          )}
        </span>
      ) : null}
    </div>
  )
}
