import {createBrowserRouter, RouterProvider} from "react-router-dom"
import React from "react"
import {LoginPage} from "../pages"

export const AnonymousRouter = () => {
  const anonymousRouter = createBrowserRouter([
    {
      path: "*",
      element: <LoginPage />
    }
  ])
  return <RouterProvider router={anonymousRouter} />
}
