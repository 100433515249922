import React from "react"
import {List, Lists, Optionable, toList} from "@damntools.fr/types"
import {Category} from "@damntools.fr/wnab-data"
import {CategoryApiService} from "../service"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent} from "@damntools.fr/react-utils"

export type CategoryProviderState = {
  categories: List<Category>
  parentCategories: List<Category>
  subCategories: List<Category>
  getCategoryByName: (name: string) => Optionable<Category>
  refresh: () => void
}

export const CategoryContext = React.createContext({} as CategoryProviderState)

export const CategoryConsumer = CategoryContext.Consumer

export class CategoryProvider extends ReactComponent<
  any,
  CategoryProviderState
> {
  private static INSTANCE: CategoryProvider | null = null

  constructor(props: any) {
    super(props, Logging.getLogger("AccountProvider"))
    CategoryProvider.INSTANCE = this
  }

  protected getInitialState(): CategoryProviderState {
    return {
      getCategoryByName: this.getCategoryByName.bind(this),
      categories: Lists.empty(),
      subCategories: Lists.empty(),
      parentCategories: Lists.empty(),
      refresh: () => {
        return this.prepareData()
      }
    }
  }

  static refresh() {
    if (this.INSTANCE) return this.INSTANCE.state.refresh()
  }

  componentDidMount() {
    void this.prepareData()
  }

  prepareData() {
    return CategoryApiService.get()
      .getAll()
      .then(
        categories =>
          new Promise<List<Category>>(resolve => {
            const sub = categories
              .stream()
              .filter(c => !!c.parent)
              .collect(toList)
            const parent = categories
              .stream()
              .filter(c => !c.parent)
              .collect(toList)
            this.setState(
              {
                categories: categories,
                parentCategories: parent,
                subCategories: sub
              },
              () => resolve(categories)
            )
          })
      )
  }

  render() {
    return (
      <CategoryContext.Provider value={this.state}>
        {this.props.children}
      </CategoryContext.Provider>
    )
  }

  private getCategoryByName(name: string): Optionable<Category> {
    return this.state.categories.stream().findOptional(a => a.name === name)
  }
}
