import {createBrowserRouter, RouterProvider} from "react-router-dom"
import type {RouteObject} from "react-router"
import popinStyles from "./Popin.module.scss"
import notificationStyles from "./Notification.module.scss"
import {
  AccountView,
  AppContent,
  BudgetLandingPage,
  CategoryView,
  FlagView,
  PeerView,
  ReportPage,
  SettingsPage,
  TransactionOverview
} from "../pages"
import {
  AccountProvider,
  CategoryProvider,
  PeerProvider,
  TransactionProvider
} from "../provider"
import React, {ReactElement} from "react"
import {LoaderDiv} from "../component"
import {AlertClasses, AlertProvider} from "@damntools.fr/react-alert"

const getRoute = (path: string, element: ReactElement): RouteObject => ({
  path,
  element: <AppContent>{element}</AppContent>
})

export const AuthenticatedRouter = () => {
  const authenticatedRouter = createBrowserRouter([
    getRoute("/budget", <BudgetLandingPage />),
    getRoute("/budget/:month", <BudgetLandingPage />),
    getRoute("/account", <LoaderDiv />),
    getRoute("/account/:id", <AccountView />),
    getRoute("/bugreport", <LoaderDiv />),
    getRoute("/donate", <LoaderDiv />),
    getRoute("/options", <LoaderDiv />),
    getRoute("/settings", <SettingsPage />),
    getRoute("/flag", <FlagView />),
    getRoute("/peer", <PeerView />),
    getRoute("/category", <CategoryView />),
    getRoute("/report", <ReportPage />),
    getRoute("/report/:type", <ReportPage />),
    getRoute("/overview", <TransactionOverview />),
    getRoute("/", <LoaderDiv />),
    getRoute("*", <div>Error</div>)
  ])
  let alertClasses: AlertClasses = {
    popin: {
      actionRow: popinStyles.ActionRow,
      closeButton: popinStyles.CloseButton,
      container: popinStyles.Container,
      content: popinStyles.Content,
      header: popinStyles.Header,
      root: popinStyles.Root,
      subtitle: popinStyles.Subtitle,
      title: popinStyles.Title
    },
    notification: {
      container: notificationStyles.Container,
      closeButton: notificationStyles.Close,
      content: notificationStyles.Content,
      icon: notificationStyles.Icon,
      root: notificationStyles.Root,
      subtitle: notificationStyles.Subtitle,
      title: notificationStyles.Title,
      types: {
        error: notificationStyles.Error,
        info: notificationStyles.Info,
        success: notificationStyles.Success,
        warning: notificationStyles.Warning
      }
    }
  }
  return (
    <AccountProvider>
      <PeerProvider>
        <CategoryProvider>
          <TransactionProvider>
            <AlertProvider
              classes={alertClasses}
              theme={"dark"}
              defaultNotificationTimeout={5000}>
              <RouterProvider router={authenticatedRouter} />
            </AlertProvider>
          </TransactionProvider>
        </CategoryProvider>
      </PeerProvider>
    </AccountProvider>
  )
}
