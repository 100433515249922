import React from "react"
import {List, Optionable, Optional} from "@damntools.fr/types"
import styles from "./TransactionTableToolbar.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {
  png_asterisk,
  png_bank,
  png_category,
  png_close,
  png_menu_overview,
  png_peer_logo,
  png_script
} from "../../../../assets"
import {TextInput, VD} from "@damntools.fr/react-inputs"
import {Account, RichTransaction} from "@damntools.fr/wnab-data"
import {SearchType, TransactionTableConsumer} from "../../../../provider"
import {
  confirmTxClear,
  confirmTxDeletion,
  confirmTxDuplication,
  openTransactionEditPopup
} from "../../../popin"
import {Strings, tsl} from "../../../../i18n"

export type TransactionTableToolbarProps = {
  transactions: List<RichTransaction>
  account: Optionable<Account>
}

export type TransactionTableToolbarState = {}

export class TransactionTableToolbar extends React.Component<
  TransactionTableToolbarProps,
  TransactionTableToolbarState
> {
  constructor(props: TransactionTableToolbarProps) {
    super(props)
    this.state = {optionExpand: Optional.empty()}
  }

  render() {
    return (
      <div className={styles.TransactionTableToolbar}>
        <TransactionTableConsumer>
          {options => {
            const hasOne = options.selected.size() === 1
            const atLeastOne = options.selected.hasElements()
            return (
              <div>
                <div
                  className={styles.ToolbarButton}
                  onClick={() => this.onClickAdd()}>
                  <div>{tsl(Strings.transactionTable.toolbar.add)}</div>
                </div>
                {hasOne ? (
                  <div
                    className={styles.ToolbarButton}
                    onClick={() => this.onClickEdit(options.selected)}>
                    <div>{tsl(Strings.transactionTable.toolbar.edit)}</div>
                  </div>
                ) : null}
                {atLeastOne ? (
                  <div
                    className={styles.ToolbarButton}
                    onClick={() => this.onClickDelete(options.selected)}>
                    <div>{tsl(Strings.transactionTable.toolbar.delete)}</div>
                  </div>
                ) : null}
                {atLeastOne ? (
                  <div
                    className={styles.ToolbarButton}
                    onClick={() => this.onClickClear(options.selected)}>
                    <div>{tsl(Strings.transactionTable.toolbar.clear)}</div>
                  </div>
                ) : null}
                {hasOne ? (
                  <div
                    className={styles.ToolbarButton}
                    onClick={() => this.onClickDuplicate(options.selected)}>
                    <div>{tsl(Strings.transactionTable.toolbar.duplicate)}</div>
                  </div>
                ) : null}
                <div className={styles.ToolbarSeparator}>
                  <div>|</div>
                </div>
                <div className={CssClass.from(styles.ToolbarFilterBar).get()}>
                  <div>
                    <div>
                      <div
                        className={CssClass.from(styles.SearchContainer).get()}>
                        <TextInput
                          dark
                          hideFormat
                          fontSize={"10px"}
                          placeholder={tsl(
                            Strings.transactionTable.toolbar.searchPlaceholder
                          )}
                          value={options.search.map(VD)}
                          onChange={options.setSearch}
                        />
                        <img
                          className={CssClass.from(styles.SearchIcon).get()}
                          onClick={() => options.toggleSearchType()}
                          src={this.getSearchTypeIcon(options.searchType)}
                          alt={options.searchType}
                          title={`${tsl(Strings.transactionTable.toolbar.activeSearch)}: ${this.getSearchTypeTranslation(options.searchType)}`}
                        />
                        <img
                          className={CssClass.from(styles.SearchIcon, styles.ResetSearch).get()}
                          onClick={() =>
                            options.setFullSearch("all", Optional.empty())
                          }
                          src={png_close}
                          alt={"clear"}
                          title={tsl(
                            Strings.transactionTable.toolbar.clearSearch
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          }}
        </TransactionTableConsumer>
      </div>
    )
  }

  private getSearchTypeIcon(searchType: SearchType) {
    if (searchType === "category") return png_category
    else if (searchType === "peer") return png_peer_logo
    else if (searchType === "description") return png_menu_overview
    else if (searchType === "account") return png_bank
    else if (searchType === "complex") return png_script
    else return png_asterisk
  }

  private getSearchTypeTranslation(searchType: SearchType) {
    if (searchType === "category")
      return tsl(Strings.transactionTable.toolbar.searchTypes.category)
    else if (searchType === "peer")
      return tsl(Strings.transactionTable.toolbar.searchTypes.peer)
    else if (searchType === "description")
      return tsl(Strings.transactionTable.toolbar.searchTypes.description)
    else if (searchType === "account")
      return tsl(Strings.transactionTable.toolbar.searchTypes.account)
    else if (searchType === "complex")
      return tsl(Strings.transactionTable.toolbar.searchTypes.complex)
    else return tsl(Strings.transactionTable.toolbar.searchTypes.all)
  }

  private onClickDelete(selected: List<RichTransaction>) {
    return confirmTxDeletion(selected)
  }

  private onClickClear(selected: List<RichTransaction>) {
    return confirmTxClear(selected)
  }

  private onClickDuplicate(selected: List<RichTransaction>) {
    return confirmTxDuplication(selected)
  }

  private onClickAdd() {
    return openTransactionEditPopup(Optional.empty(), this.props.account)
  }

  private onClickEdit(selected: List<RichTransaction>) {
    const tx = selected.first()
    return openTransactionEditPopup(tx, this.props.account)
  }
}
