import React from "react"
import {ChoiceSelector, TextInput, VD} from "@damntools.fr/react-inputs"
import {Optionable, Optional, toList} from "@damntools.fr/types"
import {CreatePeerDto, Peer, PeerType} from "@damntools.fr/wnab-data"
import {PeerApiService} from "../../../service"
import {PeerProvider} from "../../../provider"
import {PopinForm, PopinFormProps, PopinFormState} from "../PopinForm"
import {Check, Checks, Validator} from "@damntools.fr/validators"
import {getPeerTypeValues} from "../../../utils"
import {Strings, tsl} from "../../../i18n"
import {HiddenToggle} from "../../static"

export type PeerEditViewProps = PopinFormProps & {
  peer: Optionable<Peer>
}

export type PeerEditViewState = PopinFormState & {
  hidden: Optionable<boolean>
  name: Optionable<string>
  type: Optionable<PeerType>
}

export const openPeerViewPopup = (peer?: Peer) =>
  PopinForm.open<PeerEditViewProps, PeerEditView>(
    {
      peer: Optional.nullable(peer),
      title: peer
        ? `${tsl(Strings.resources.peer.update)} : ${peer.name}`
        : tsl(Strings.resources.peer.create)
    },
    PeerEditView
  )

export class PeerEditView extends PopinForm<
  PeerEditViewProps,
  PeerEditViewState,
  PeerEditViewState
> {
  constructor(props: PeerEditViewProps) {
    super(props, "PeerEditView")
  }

  protected getInitialState(): PeerEditViewState {
    if (this.props.peer.isPresent()) {
      const peer = this.props.peer.get()
      return {
        hidden: Optional.of(peer.hidden),
        name: Optional.of(peer.name),
        type: Optional.of(peer.type)
      }
    } else {
      return {
        hidden: Optional.of(false),
        name: Optional.empty(),
        type: Optional.empty()
      }
    }
  }

  protected getValidator(): Validator<PeerEditViewState> {
    return new Validator()
      .addCheck(
        Check.field("name").with(
          Check.optional()
            .provided(tsl(Strings.popins.peerEdit.error.emptyName))
            .valueCheck(
              Checks.String.matches(/^[a-zA-Z0-9.\s,_-]+$/g),
              tsl(Strings.popins.peerEdit.error.badNamePattern)
            )
        )
      )
      .addCheck(
        Check.field("type").with(
          Check.optional().provided(
            tsl(Strings.popins.peerEdit.error.emptyType)
          )
        )
      )
  }

  getForm() {
    return (
      <div>
        {this.getRow(
          tsl(Strings.popins.peerEdit.fields.name),
          <TextInput
            dark={true}
            onChange={v => this.onChangeOptional("name", v)}
            hideFormat={true}
            value={this.state.name.map(VD)}
          />
        )}
        {this.getRow(
          tsl(Strings.popins.peerEdit.fields.type),
          <ChoiceSelector
            dark={true}
            onChange={v => this.onChangeOptional("type", v)}
            values={getPeerTypeValues()}
            selectedValues={this.state.type.map(VD).toStream().collect(toList)}
          />
        )}
        {this.getRow(
          tsl(Strings.popins.peerEdit.fields.hidden),
          <HiddenToggle
            hidden={this.state.hidden.orElseReturn(false)}
            onChange={v => this.onChangeOptional("hidden", v)}
          />
        )}
      </div>
    )
  }

  protected onSuccess(model: PeerEditViewState) {
    const peer: CreatePeerDto = {
      hidden: model.hidden.orElseReturn(false),
      name: model.name.get(),
      type: model.type.map(t => t.key().toUpperCase()).get()
    }
    if (this.props.peer.isPresent()) {
      const id = this.props.peer.map(p => p.id).get()
      void PeerApiService.get()
        .update({...peer, id})
        .then(() => PeerProvider.refresh())
        .catch(err =>
          this.processError(err, tsl(Strings.popins.peerEdit.error.updateError))
        )
    } else {
      void PeerApiService.get()
        .create(peer)
        .then(() => PeerProvider.refresh())
        .catch(err =>
          this.processError(err, tsl(Strings.popins.peerEdit.error.createError))
        )
    }
  }
}
