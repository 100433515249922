import {AxiosWrapper} from "@damntools.fr/http"
import {Globals} from "@damntools.fr/react-globals"
import {
  UserProfile,
  UserProfileDto,
  UserProfileDtoMapper
} from "@damntools.fr/wnab-data"
import {AxiosService} from "./AxiosService"
import {LocalStorageManager} from "./CustomLocalStorageManager"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"

export type KeycloakLoginResponse = {
  access_token: string
  expires_in: number
  refresh_expires_in: number
  refresh_token: string
  token_type: string
  "not-before-policy": string
  session_state: string
}

export class AuthenticationService {
  static INSTANCE: AuthenticationService | null = null
  private readonly keycloak: AxiosWrapper
  private readonly url: string
  private readonly clientId: string
  private readonly realm: string

  constructor() {
    this.realm = AuthenticationService.getKeycloakRealm()
    this.clientId = AuthenticationService.getKeycloakClientId()
    this.url = AuthenticationService.getKeycloakUrl()

    this.keycloak = new AxiosWrapper({
      baseURL: `${this.url}/realms/${this.realm}`
    })
  }

  public login(login: string, password: string): Promise<void> {
    return this.keycloak
      .post<KeycloakLoginResponse>(
        "/protocol/openid-connect/token",
        {
          grant_type: "password",
          client_id: this.clientId,
          password: password,
          username: login
        },
        {headers: {"Content-Type": "application/x-www-form-urlencoded"}}
      )
      .then(r => r.data)
      .then(token => LocalStorageManager.setAuthentication(token.access_token))
      .then(() => {
        console.log("Reload")
        window.location.reload()
      })
      .catch(err => {
        void AlertProvider.submit(
          Notification.error("Invalid login").Subtitle(
            "Could not authenticate !"
          )
        )
        throw err
      })
  }

  public getUserProfile(): Promise<UserProfile> {
    return AxiosService.getAuthenticatedInstance()
      .get<UserProfileDto>("/auth/user")
      .then(res => res.data)
      .then(res => UserProfileDtoMapper.get().mapTo(res))
  }

  public logout(): Promise<void> {
    return LocalStorageManager.removeAuthentication().then(() => {
      window.location.replace("/")
      console.log("Logout / reload")
      window.location.reload()
    })
  }

  private static getKeycloakUrl(): string {
    return Globals.get<string>("auth.keycloak.url").orElseThrow(
      () => new Error("Keycloak url should be provided as global !")
    )
  }

  private static getKeycloakClientId(): string {
    return Globals.get<string>("auth.keycloak.clientId").orElseThrow(
      () => new Error("Keycloak clientId should be provided as global !")
    )
  }

  private static getKeycloakRealm(): string {
    return Globals.get<string>("auth.keycloak.realm").orElseThrow(
      () => new Error("Keycloak realm should be provided as global !")
    )
  }

  static get(): AuthenticationService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new AuthenticationService()
    }
    return this.INSTANCE
  }
}
