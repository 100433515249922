import React from "react"
import styles from "./CategoryView.module.scss"
import {Category} from "@damntools.fr/wnab-data"
import {AlertProvider, Notification} from "@damntools.fr/react-alert"
import {
  confirmCategoryDeletion,
  HiddenToggle,
  openCategoryEditPopup
} from "../../component"
import {png_delete2, png_menu_edit} from "../../assets"
import {CategoryApiService} from "../../service"
import {CategoryProvider} from "../../provider"
import {Strings, tsl} from "../../i18n"

export type CategoryParentEntryProps = {
  category: Category
}

export class CategorySubEntry extends React.Component<
  CategoryParentEntryProps,
  any
> {
  render() {
    const category = this.props.category
    return (
      <div className={styles.CategorySubEntry}>
        <div>
          <div className={styles.SubName}>{category.name}</div>
          <div
            className={styles.SubHidden}
            title={tsl(
              category.hidden
                ? Strings.views.category.hidden
                : Strings.views.category.notHidden
            )}>
            <HiddenToggle
              hidden={category.hidden}
              onChange={v => this.onChangeHidden(v)}
            />
          </div>
          <div className={styles.SubEdit}>
            <div
              onClick={() => openCategoryEditPopup(category, category.parent)}>
              <span></span>
              <img
                src={png_menu_edit}
                alt={"edit"}
                title={tsl(Strings.views.category.edit)}
              />
            </div>
          </div>
          <div className={styles.SubEdit}>
            <div onClick={() => confirmCategoryDeletion(category)}>
              <span></span>
              <img
                src={png_delete2}
                alt={"delete"}
                title={tsl(Strings.views.category.delete)}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  onChangeHidden(value: boolean) {
    CategoryApiService.get()
      .update({id: this.props.category.id, hidden: value})
      .then(() => CategoryProvider.refresh())
      .catch(err => {
        console.error("err", err)
        AlertProvider.submit(
          Notification.error(
            tsl(Strings.views.category.hiddenErrorMessage)
          ).Subtitle(err?.response?.data?.reason || "")
        )
      })
  }
}
