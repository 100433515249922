import React from "react"
import styles from "./ReportView.module.scss"
import {KV, List, Optionable, toList} from "@damntools.fr/types"
import {ReportGroupBy, ReportPageConsumer} from "../../../provider"
import {GroupEntry, ReportUtils} from "../../../utils"
import {ReportViewProps} from "./ReportView.props"
import {RichTransaction} from "@damntools.fr/wnab-data"
import {DefaultPieChart, PieChartColors} from "../../../component"

export function OverviewReportView(props: ReportViewProps): React.ReactElement {
  return (
    <ReportPageConsumer>
      {({groupBy}) => {
        return (
          <div className={styles.OverviewReportView}>
            {getPieChart(props.timeFilteredTransactions, groupBy)}
          </div>
        )
      }}
    </ReportPageConsumer>
  )
}

function getPieChart(
  transactions: List<RichTransaction>,
  groupBy: Optionable<ReportGroupBy>
) {
  const group = groupBy.orElseGet(() => "childCategory")
  let groups = KV.empty<string, GroupEntry<List<RichTransaction>>>()
  if (group === "peer") {
    groups = ReportUtils.groupTransactionsByPeers(transactions)
  } else if (group === "childCategory") {
    groups = ReportUtils.groupTransactionsByChildCategories(transactions)
  } else if (group === "parentCategory") {
    groups = ReportUtils.groupTransactionsByParentCategories(transactions)
  }
  const reduced = ReportUtils.reduceGroupsCashFlow(groups)
    .entries()
    .stream()
    .map(e => ({
      name: e.key,
      value: e.value.value < 0 ? e.value.value * -1 : e.value.value,
      isPositive: e.value.value < 0,
      isNegative: e.value.value > 0,
      label: e.value.label,
      subLabel: e.value.subLabel
    }))
    .collect(toList)
  const reducedData = ReportUtils.completeDataWithPercent(reduced)
    .sortWith("percent")
    .reverse()
  console.log(reducedData)
  if (reduced.isEmpty()) {
    return undefined
  }
  let data = reducedData.copy()
  let indexOfSmallGroups = 0
  let minPercent = 3
  while (indexOfSmallGroups < 7 && minPercent > 1) {
    indexOfSmallGroups = data
      .stream()
      .findFirstIndex(e => e.percent <= minPercent)
    minPercent -= 0.5
  }
  console.log(indexOfSmallGroups)
  if (data.size() > indexOfSmallGroups && indexOfSmallGroups > -1) {
    data = data.sub(0, indexOfSmallGroups)
    const left = reducedData.copy().sub(indexOfSmallGroups, reducedData.size())
    const value = left.stream().reduce((o, c) => c.value + o, 0)
    data.push({
      name: "Other",
      subLabel: `Aggregate of under ${minPercent}%`,
      value: value < 0 ? value * -1 : value,
      isPositive: value < 0,
      isNegative: value > 0,
      percent: left.stream().reduce((o, c) => c.percent + o, 0)
    })
  }
  return <DefaultPieChart data={data.getInner()} colors={PieChartColors} />
}
