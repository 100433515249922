import React, {JSX} from "react"
import styles from "./AppContent.module.scss"
import {MenuPanel, NotificationCartouche} from "../../component"

export type AppContentProps = {
  children: JSX.Element
}

export function AppContent(props: AppContentProps) {
  return (
    <div className={styles.AppContent}>
      <div className={styles.Menu}>
        <MenuPanel />
      </div>
      <div className={styles.Content}>
        <div>
          <NotificationCartouche />
          {props.children}
        </div>
      </div>
    </div>
  )
}
