import React from "react"
import {AuthenticationConsumer} from "../provider"
import {AnonymousRouter} from "./AnonymousRouter"
import {AuthenticatedRouter} from "./AuthenticatedRouter"

export function App() {
  return (
    <AuthenticationConsumer>
      {({authentication, pending}) => {
        if (pending) {
          return <div></div>
        }
        return authentication.isPresent() ? (
          <AuthenticatedRouter />
        ) : (
          <AnonymousRouter />
        )
      }}
    </AuthenticationConsumer>
  )
}
