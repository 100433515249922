import {en} from "./en"
import {t} from "i18next"
import {I18N} from "./i18N"
import {ObjectUtils} from "@damntools.fr/types"

export {en, I18N}

I18N.get().register("en", en)

export const tsl = (key: string) => {
  return t(key).replaceAll(/\{\{[^}]+}}/gi, match => {
    const subKey = match.replaceAll(/[{}]+/g, "").replace("Strings.", "")
    const found = ObjectUtils.pathAccessor(Strings, subKey)
    if (found) {
      return t(found)
    }
    return match
  })
}
export const Strings = I18N.get().prepareStrings()
