import React from "react"
import {TransactionConsumer} from "../../provider"
import {BalanceCartouche} from "../../component"

export class TransactionOverview extends React.Component<any, any> {
  render() {
    return (
      <TransactionConsumer>
        {({transactions}) => {
          return (
            <div>
              <BalanceCartouche transactions={transactions} />
            </div>
          )
        }}
      </TransactionConsumer>
    )
  }
}
