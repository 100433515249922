import React from "react"
import styles from "./PeerView.module.scss"
import {PeerEntry} from "./PeerEntry"
import {PeerConsumer} from "../../provider"
import {openPeerViewPopup} from "../../component"
import {png_add_account} from "../../assets"
import {toList} from "@damntools.fr/types"
import {Strings, tsl} from "../../i18n"
import {
  FlexColumnBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {BubbleLink, RGBA} from "@damntools.fr/react-utils"

export class PeerView extends React.Component<any, any> {
  componentDidMount() {
    document.title = tsl(Strings.views.peer.title)
  }

  render() {
    return (
      <PeerConsumer>
        {({peers}) => {
          peers = peers
            .stream()
            .filter(p => p.name !== "Reconciliation Balance Adjustment")
            .collect(toList)
          return (
            <div className={styles.PeerView}>
              <FlexColumnBlock>
                <ShrinkFlexCell className={styles.Title}>
                  <h1>{tsl(Strings.views.peer.title)}</h1>
                </ShrinkFlexCell>
                <ShrinkFlexCell className={styles.Toolbar}>
                  <div className={styles.Add}>
                    <BubbleLink
                      title={tsl(Strings.views.peer.add)}
                      icon={png_add_account}
                      bgColor={RGBA(144, 180, 33, 0.5)}
                      effect={"brightness"}
                      onClick={() => openPeerViewPopup()}
                    />
                  </div>
                </ShrinkFlexCell>
                <GrowShrinkFlexCell className={styles.List} hideOverflow>
                  <div>
                    <div>
                      {peers
                          .copy()
                          .sortWith("name")
                          .stream()
                          .map(p => <PeerEntry peer={p} key={p.id}/>)
                          .collectArray()}
                    </div>
                  </div>
                </GrowShrinkFlexCell>
              </FlexColumnBlock>
            </div>
          )
        }}
      </PeerConsumer>
    )
  }
}
