import {ArrayList, List, toList} from "@damntools.fr/types"
import {
  CreatePeerDto,
  Peer,
  PeerDto,
  PeerDtoMapper, UpdatePeerDto
} from "@damntools.fr/wnab-data"
import {AxiosWrapper} from "@damntools.fr/http"
import {AxiosService} from "../auth"

export class PeerApiService {
  static INSTANCE: PeerApiService | null = null
  private readonly axios: AxiosWrapper

  constructor() {
    this.axios = AxiosService.getAuthenticatedInstance().child({
      baseURL: "/resource/peer"
    })
  }

  getPeers(): Promise<List<Peer>> {
    return this.axios
      .get("/")
      .then(res => new ArrayList<PeerDto>(res.data))
      .then(res =>
        res
          .stream()
          .map(a => PeerDtoMapper.get().mapTo(a))
          .collect(toList)
      )
  }

  create(peer: CreatePeerDto): Promise<Peer> {
    return this.axios
      .post("/", peer)
      .then(res => res.data as PeerDto)
      .then(res => PeerDtoMapper.get().mapTo(res))
  }

  update(peer: UpdatePeerDto) {
    if (!peer.id) return Promise.reject("Peer should contains id ! ")
    return this.axios.put(`/${peer.id}`, peer)
  }

  delete(flags: List<number>) {
    return this.axios.delete(`/?ids=${flags.stream().join(",")}`).then(() => {})
  }

  static get(): PeerApiService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new PeerApiService()
    }
    return this.INSTANCE
  }
}
