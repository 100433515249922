import React from "react"
import {
  ArrayList,
  Dict,
  KV,
  List,
  Lists,
  Optionable,
  toList
} from "@damntools.fr/types"
import {Account} from "@damntools.fr/wnab-data"
import {AccountApiService, EnrichedAccount} from "../service"
import {Logging} from "@damntools.fr/logger-simple"
import {ReactComponent} from "@damntools.fr/react-utils"

export type AccountProviderState = {
  accounts: List<EnrichedAccount>
  balances: Dict<number, number>
  getAccountByName: (name: string) => Optionable<EnrichedAccount>
  refresh: () => void
}

export const AccountContext = React.createContext({} as AccountProviderState)

export const AccountConsumer = AccountContext.Consumer

export class AccountProvider extends ReactComponent<any, AccountProviderState> {
  private static INSTANCE: AccountProvider | null = null

  protected getInitialState(): AccountProviderState {
    return {
      getAccountByName: this.getAccountByName.bind(this),
      accounts: Lists.empty<EnrichedAccount>(),
      balances: KV.empty(),
      refresh: () => {
        return this.prepareData()
      }
    }
  }

  constructor(props: any) {
    super(props, Logging.getLogger("AccountProvider"))
    AccountProvider.INSTANCE = this
  }

  static refresh() {
    if (this.INSTANCE) return this.INSTANCE.state.refresh()
  }

  componentDidMount() {
    void this.prepareData()
  }

  prepareData() {
    return AccountApiService.get()
      .getAccounts()
      .then(
        accounts =>
          new Promise<List<Account>>(resolve =>
            this.setState({accounts: accounts as List<EnrichedAccount>}, () =>
              resolve(accounts)
            )
          )
      )
      .then(() => AccountApiService.get().getSplitBalances())
      .then(balances => {
        const accounts = this.state.accounts
          .stream()
          .peek(a => (a.balance = balances.getOrDefault(a.id, 0)))
          .collect(toList)
        this.setState({balances, accounts})
      })
      .catch(err => {
        this.logger.warn("Could not get accounts", err)
        this.setState({accounts: new ArrayList()})
      })
  }

  render() {
    return (
      <AccountContext.Provider value={this.state}>
        {this.props.children}
      </AccountContext.Provider>
    )
  }

  private getAccountByName(name: string): Optionable<EnrichedAccount> {
    return this.state.accounts.stream().findOptional(a => a.name === name)
  }
}
