import React from "react"
import styles from "./TransactionEditView.module.scss"
import {
  Account,
  Category,
  CreateTransactionDto,
  Peer,
  RecurringTransactionStep,
  RichTransaction,
  TransactionStatus,
  UpdateTransactionDto
} from "@damntools.fr/wnab-data"
import {List, Optionable, Optional, toList} from "@damntools.fr/types"
import {DateTime} from "luxon"
import {
  ChoiceSelector,
  SimpleCalculatorInput,
  TextInput,
  ValueDesc,
  VD
} from "@damntools.fr/react-inputs"
import {EnrichedAccount, TransactionApiService} from "../../../service"
import {
  TransactionEditViewProps,
  TransactionEditViewState
} from "./TransactionEditView.types"
import {DatePicker, FlagSelector} from "../../static"
import {
  AccountConsumer,
  CategoryConsumer,
  PeerConsumer,
  PeerProvider,
  TransactionProvider
} from "../../../provider"
import {PopinForm} from "../PopinForm"
import {Check, Validator} from "@damntools.fr/validators"
import {
  getAccountValues,
  getCategoryValues,
  getPeerValues,
  getRecurringValues,
  getStatusValues
} from "../../../utils"
import {Strings, tsl} from "../../../i18n"

export const openTransactionEditPopup = (
  tx: Optionable<RichTransaction>,
  account: Optionable<Account>
) =>
  PopinForm.open<TransactionEditViewProps, TransactionEditView>(
    {
      tx: tx,
      account: account,
      title: tx.isPresent()
        ? tsl(Strings.resources.transaction.update)
        : tsl(Strings.resources.transaction.create)
    },
    TransactionEditView
  )

export class TransactionEditView extends PopinForm<
  TransactionEditViewProps,
  TransactionEditViewState,
  TransactionEditViewState
> {
  constructor(props: TransactionEditViewProps) {
    super(props, "TransactionEditView")
  }

  protected getInitialState(): TransactionEditViewState {
    if (this.props.tx.isPresent()) {
      const tx = this.props.tx.get()
      return {
        date: Optional.of(tx.date),
        description: Optional.of(tx.description),
        flag: Optional.nullable(tx.flag),
        cashFlow: Optional.of(tx.cashFlow),
        status: Optional.of(tx.status),
        category: Optional.nullable(tx.category),
        peer: Optional.nullable(tx.peer),
        recurring: Optional.empty(),
        account: Optional.nullable(tx.account)
      }
    } else {
      return {
        date: Optional.of(DateTime.now()),
        description: Optional.empty(),
        flag: Optional.empty(),
        cashFlow: Optional.empty(),
        status: Optional.of(TransactionStatus.UNCLEARED),
        category: Optional.empty(),
        peer: Optional.empty(),
        recurring: Optional.empty(),
        account: this.props.account
      }
    }
  }

  protected getForm() {
    return (
      <AccountConsumer>
        {({accounts}) => {
          return (
            <CategoryConsumer>
              {({subCategories}) => {
                return (
                  <PeerConsumer>
                    {({peers}) => {
                      return this.getContent(peers, accounts, subCategories)
                    }}
                  </PeerConsumer>
                )
              }}
            </CategoryConsumer>
          )
        }}
      </AccountConsumer>
    )
  }

  private getContent(
    peers: List<Peer>,
    accounts: List<EnrichedAccount>,
    subCategories: List<Category>
  ) {
    const peerValues = getPeerValues(peers)
    const accountsValues = getAccountValues(accounts)
    const recurringValues = getRecurringValues()
    const categoryValues = getCategoryValues(subCategories)
    const selectedPeer = this.getSelectedPeer(peerValues)
    const selectedCategory = this.getSelectedCategory(categoryValues)
    const selectedAccount = this.getSelectedAccount(accountsValues)
    const selectedRecurring = this.getSelectedRecurring(recurringValues)
    const selectedStatus = this.getSelectedStatus()
    return (
      <div>
        <div className={styles.Rows}>
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.date),
            <DatePicker
              value={this.state.date}
              onChange={v => this.onChangeDate(Optional.nullable(v))}
            />
          )}
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.repeat),
            this.getDropdown(
              v => this.onChangeOptional("recurring", v),
              recurringValues,
              selectedRecurring
            )
          )}
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.amount),
            <SimpleCalculatorInput
              fontSize={"12px"}
              unit={"€"}
              precision={2}
              dark
              focus
              onChange={v => this.onChangeOptional("cashFlow", v)}
              value={this.state.cashFlow.map(VD)}
            />
          )}
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.description),
            <TextInput
              fontSize={"12px"}
              hideFormat
              dark
              onChange={v => this.onChangeOptional("description", v)}
              value={this.state.description.map(VD)}
            />
          )}
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.status),
            <ChoiceSelector
              dark
              onChange={v => this.onChangeOptional("status", v)}
              values={getStatusValues()}
              selectedValues={selectedStatus}
            />,
            "middle"
          )}
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.flag),
            <FlagSelector
              onChange={v => this.onChangeOptional("flag", v)}
              selected={this.state.flag}
            />,
            "middle"
          )}
        </div>
        <div className={styles.Rows}>
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.account),
            this.getDropdown(
              v => this.onChangeOptional("account", v),
              accountsValues,
              selectedAccount,
              undefined,
              undefined,
              true
            )
          )}
          {this.getRow(
            tsl(Strings.popins.transactionEdit.fields.peer),
            this.getDropdown(
              v => this.onChangeOptional("peer", v),
              peerValues,
              selectedPeer
            )
          )}
          {this.props.account.filter(acc => acc.type.isHot()).isPresent()
            ? this.getRow(
                tsl(Strings.popins.transactionEdit.fields.category),
                this.getDropdown(
                  v => this.onChangeOptional("category", v),
                  categoryValues,
                  selectedCategory
                )
              )
            : null}
        </div>
      </div>
    )
  }

  private getSelectedStatus() {
    return this.state.status.map(VD).toStream().collect(toList)
  }

  private getSelectedRecurring(
    recurringValues: List<ValueDesc<RecurringTransactionStep>>
  ) {
    return recurringValues
      .stream()
      .filter(vd =>
        this.state.recurring.filter(p => p.equals(vd.returnValue)).isPresent()
      )
      .collect(toList)
  }

  private getSelectedAccount(accountsValues: List<ValueDesc<Account>>) {
    return accountsValues
      .stream()
      .filter(vd =>
        this.state.account.filter(p => p.id === vd.returnValue.id).isPresent()
      )
      .collect(toList)
  }

  private getSelectedCategory(categoryValues: List<ValueDesc<Category>>) {
    return categoryValues
      .stream()
      .filter(vd =>
        this.state.category.filter(p => p.id === vd.returnValue.id).isPresent()
      )
      .collect(toList)
  }

  private getSelectedPeer(peerValues: List<ValueDesc<Peer>>) {
    return peerValues
      .stream()
      .filter(vd =>
        this.state.peer.filter(p => p.id === vd.returnValue.id).isPresent()
      )
      .collect(toList)
  }

  private onChangeDate(value: Optionable<DateTime>) {
    this.setState({date: value.log()})
  }

  protected getValidator(): Validator<TransactionEditViewState> {
    return new Validator()
      .addCheck(
        Check.field("status").with(
          Check.optional().provided(
            tsl(Strings.popins.transactionEdit.error.emptyStatus)
          )
        )
      )
      .addCheck(
        Check.field("cashFlow").with(
          Check.optional().provided(
            tsl(Strings.popins.transactionEdit.error.emptyAmount)
          )
        )
      )
      .addCheck(
        Check.field("date").with(
          Check.optional().provided(
            tsl(Strings.popins.transactionEdit.error.emptyAmount)
          )
        )
      )
      .addCheck(
        Check.field("account").with(
          Check.optional().provided(
            tsl(Strings.popins.transactionEdit.error.emptyAccount)
          )
        )
      )
  }

  protected onSuccess(model: TransactionEditViewState) {
    const tx: CreateTransactionDto = {
      accountId: model.account.map(a => a.id).get(),
      cashFlow: model.cashFlow.get(),
      categoryId: model.category.map(c => c.id).orElseUndefined(),
      date: model.date.map(d => d.toMillis()).get(),
      description: model.description.orElseReturn(""),
      flagId: model.flag.map(f => f.id).orElseUndefined(),
      peerId: model.peer.map(p => p.id).orElseUndefined(),
      repeat: model.recurring.map(t => t.key()).orElseUndefined(),
      repeated: model.recurring.map(() => true).orElseReturn(false),
      status: model.status.map(s => s.key().toUpperCase()).get()
    }
    if (this.props.tx.isPresent()) {
      const id = this.props.tx.map(t => t.id).get()
      const update: UpdateTransactionDto = {
        ...tx,
        id,
        detachFlag: model.flag.isEmpty(),
        detachTransferAccount: model.account.isEmpty(),
        detachCategory: model.category.isEmpty(),
        detachPeer: model.peer.isEmpty()
      }
      return TransactionApiService.get()
        .updateTx(update)
        .then(() => TransactionProvider.refresh())
        .then(() => PeerProvider.refresh())
        .catch(err => this.processError(err, "Could not update transaction !")) //TODO tsl
    } else {
      return TransactionApiService.get()
        .createTx(tx)
        .then(() => TransactionProvider.refresh())
        .then(() => PeerProvider.refresh())
        .catch(err => this.processError(err, "Could not create transaction !"))
    }
  }
}
