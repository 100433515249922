import {
  ReactComponent,
  ReactComponentProps,
  ReactComponentState
} from "@damntools.fr/react-utils"
import {Logging} from "@damntools.fr/logger-simple"
import React, {ReactElement} from "react"
import styles from "./PopinForm.module.scss"
import {
  AlertProvider,
  Notification,
  Popin,
  PopinActions
} from "@damntools.fr/react-alert"
import {Category} from "@damntools.fr/wnab-data"
import {
  ClassType,
  isOptional,
  List,
  Optionable,
  Optional
} from "@damntools.fr/types"
import {ValidationFailedError, Validator} from "@damntools.fr/validators"
import {SearchDropDown, ValueDesc} from "@damntools.fr/react-inputs"

export type PopinFormProps = ReactComponentProps & {
  popin: PopinActions
  title: string
  onSave?: (category: Category) => Promise<any>
  onUpdate?: (category: Category) => Promise<any>
}
export type PopinFormState = ReactComponentState & {}

export abstract class PopinForm<
  P extends PopinFormProps,
  S extends PopinFormState,
  M
> extends ReactComponent<P, S> {
  private readonly validator: Validator<S>

  protected constructor(props: any, logger: string) {
    super(props, Logging.getLogger(logger))
    this.validator = this.getValidator()
  }

  componentDidMount() {
    this.store()
  }

  /**
   * Do not use this.state as this method will not have the real component state. could not find a way to bind it
   * @param model What is manually  stored in the popin store
   * @protected
   */
  protected abstract onSuccess(model: M): any

  protected abstract getForm(): ReactElement

  protected abstract getValidator(): Validator<S>

  public validate(model: S) {
    return this.validator.validate(model)
  }

  protected onChange<X>(
    key: keyof S,
    value: Optionable<ValueDesc<X>> | Optionable<X> | X
  ) {
    let stateValue: X | undefined
    if (isOptional(value)) {
      value.ifPresentOrElse(
        v => (stateValue = v instanceof ValueDesc ? v.returnValue : v),
        () => (stateValue = undefined)
      )
    } else {
      stateValue = value
    }
    const state = {} as any
    state[key] = stateValue
    this.setState(state, () => this.store())
  }

  protected onChangeOptional<X>(
    key: keyof S,
    value: Optionable<ValueDesc<X>> | Optionable<X> | X
  ) {
    let stateValue: X | undefined
    if (isOptional(value)) {
      value.ifPresentOrElse(
        v => (stateValue = v instanceof ValueDesc ? v.returnValue : v),
        () => (stateValue = undefined)
      )
    } else {
      stateValue = value
    }
    const state = {} as any
    state[key] = Optional.nullable(stateValue)
    this.setState(state, () => this.store())
  }

  render() {
    return (
      <div className={styles.Form}>
        <div className={styles.Rows}>{this.getForm()}</div>
      </div>
    )
  }

  protected getRow(
    label: string,
    input: ReactElement,
    align?: string
  ): ReactElement {
    return (
      <div className={styles.FormRow}>
        <div
          className={styles.RowLabel}
          style={{verticalAlign: align || "top"}}>
          <span>{label}</span>
        </div>
        <div className={styles.RowInput}>{input}</div>
      </div>
    )
  }

  protected getDropdown(
    onChange: (v: Optionable<ValueDesc<any>>) => void,
    values: List<any>,
    selectedValues: List<any>,
    onSearch?: (value: Optionable<string>) => void,
    maxHeight?: string,
    readonly?: boolean
  ) {
    return (
        <div className={styles.DropDownContainer}>
          <SearchDropDown
              onChange={onChange}
              values={values}
              selectedValues={selectedValues}
              dark
              maxDropdownHeight={maxHeight || "150px"}
              onSearchChange={onSearch}
              readOnly={readonly}
          />
        </div>
      /*<DropDownSelector
              maxHeight={maxHeight || "128px"}
              onChange={onChange}
              values={values}
              showSelection
              dark
              readOnly={!!readonly}
              showValuesOnFocus
              selectedValues={selectedValues}
              onSearchChange={onSearch}
            />*/
    )
  }

  protected store() {
    return this.props.popin.store(this.state)
  }

  private validationIssue(title: string) {
    title = title.replaceAll(/<[^>]+>/gi, "")
    return AlertProvider.submit(
      Notification.error("Validation issue").Subtitle(title)
    )
  }

  protected processError(err: Error, title: string) {
    console.error(title, err)
    const error = err as any
    return AlertProvider.submit(
      Notification.error(title).Subtitle(error?.response?.data?.reason || "")
    )
  }

  protected processSuccess(title: string) {
    return AlertProvider.submit(Notification.success(title))
  }

  public static open<
    P extends PopinFormProps,
    T extends PopinForm<P, any, any>
  >(
    formProps: Omit<P, "popin" | "onSave" | "componentName" | "onUpdate">,
    type: ClassType<T>
  ): Promise<any> {
    return AlertProvider.submit(
      Popin.title(formProps.title)
        .OnSuccess(m => {
          const form = new type(formProps)
          return form
            .validate(m)
            .then(() => form.onSuccess(m))
            .catch(err => {
              console.log(err)
              if (err instanceof ValidationFailedError) {
                return form.validationIssue(err.getFirst().message).then(() => {
                  throw err
                })
              } else {
                throw err //TODO
              }
            })
        })
        .Content(popin =>
          React.createElement(type, {...formProps, popin: popin})
        )
    )
  }
}
