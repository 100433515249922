import React from "react"
import styles from "./CategoryView.module.scss"
import {CategoryParentEntry} from "./CategoryParentEntry"
import {List, toList} from "@damntools.fr/types"
import {Category} from "@damntools.fr/wnab-data"
import {CategoryConsumer} from "../../provider"
import {openCategoryEditPopup} from "../../component"
import {png_add_account, png_move_file} from "../../assets"
import {
  FlexColumnBlock,
  GrowShrinkFlexCell,
  ShrinkFlexCell
} from "@damntools.fr/react-layout"
import {BubbleLink, RGBA} from "@damntools.fr/react-utils"
import {Strings, tsl} from "../../i18n"
import {openMoveCategoryTransactionPopup} from "../../component/popin/MoveCategoryTransactionsView"

export class CategoryView extends React.Component<any, any> {
  componentDidMount() {
    document.title = tsl(Strings.views.category.title)
  }

  render() {
    return (
      <CategoryConsumer>
        {({parentCategories, subCategories}) => {
          return (
            <div className={styles.CategoryView}>
              <FlexColumnBlock>
                <ShrinkFlexCell className={styles.Title}>
                  <h1>{tsl(Strings.views.category.title)}</h1>
                </ShrinkFlexCell>
                <ShrinkFlexCell className={styles.Toolbar}>
                  <div>
                    <div className={styles.Add}>
                      <BubbleLink
                        title={tsl(Strings.views.category.addParent)}
                        icon={png_add_account}
                        bgColor={RGBA(144, 180, 33, 0.5)}
                        effect={"brightness"}
                        onClick={() => openCategoryEditPopup()}
                      />
                      <BubbleLink
                        title={tsl(Strings.views.category.moveCategories)}
                        icon={png_move_file}
                        bgColor={RGBA(36, 133, 175, 0.5)}
                        effect={"brightness"}
                        onClick={() => openMoveCategoryTransactionPopup()}
                      />
                    </div>
                  </div>
                </ShrinkFlexCell>
                <GrowShrinkFlexCell className={styles.List} hideOverflow>
                  <div>
                    <div>
                      {parentCategories
                        .copy()
                        .sortWith("name")
                        .stream()
                        .filter(c => c.name.toLowerCase() !== "inflow")
                        .map(cat => (
                          <CategoryParentEntry
                            key={cat.id}
                            category={cat}
                            subCategories={this.getSub(cat, subCategories)}
                          />
                        ))
                        .collectArray()}
                    </div>
                  </div>
                </GrowShrinkFlexCell>
              </FlexColumnBlock>
            </div>
          )
        }}
      </CategoryConsumer>
    )
  }

  private getSub(cat: Category, subCategories: List<Category>): List<Category> {
    return subCategories
      .stream()
      .filter(c => c.parent?.id === cat.id)
      .collect(toList)
  }
}
