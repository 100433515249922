import React from "react"
import {Account, AccountType} from "@damntools.fr/wnab-data"
import styles from "./AccountInfoCartouche.module.scss"
import {CssClass} from "@damntools.fr/utils-simple"
import {
  openAccountReconcilePopup,
  openAccountViewPopup,
  openClearAllForAccountPopin,
  openCloseAccountPopin,
  openOpenAccountPopin
} from "../../popin"
import {Strings, tsl} from "../../../i18n"
import {getAccountTypeIcon} from "../../../utils"

export type AccountInfoCartoucheProps = {
  account: Account
  showButtons: boolean
}

export class AccountInfoCartouche extends React.Component<
  AccountInfoCartoucheProps,
  any
> {
  render() {
    const account = this.props.account
    return (
      <div className={styles.AccountInfo}>
        <div>
          <div>
            <h1>{account.name}</h1>
            <h2>
              <span></span>
              <img
                src={getAccountTypeIcon(account.type)}
                alt={account.type.key()}
                title={account.type.key()}
              />
              <span>
                {`${this.getAccountTypeTranslation(account.type)} ${tsl(Strings.resources.account.single).toLowerCase()}`}
              </span>
            </h2>
          </div>
        </div>
        {this.props.showButtons ? (
          <div className={CssClass.from(styles.Actions).get()}>
            <div>
              <div className={styles.Configure}>
                <span onClick={() => this.onConfigureClick(account)}>
                  {tsl(Strings.views.cartouches.account.buttons.configure)}
                </span>
              </div>
              <div className={styles.Reconcile}>
                <span onClick={() => this.onReconcileClick(account)}>
                  {tsl(Strings.views.cartouches.account.buttons.reconcile)}
                </span>
              </div>
              <div className={styles.ClearAll}>
                <span onClick={() => this.onClearClick(account)}>
                  {tsl(Strings.views.cartouches.account.buttons.clearAll)}
                </span>
              </div>
              <div
                className={CssClass.classIf(
                  styles.Open,
                  account.closed,
                  styles.Close
                ).get()}>
                <span onClick={() => this.onCloseClick(account)}>
                  {tsl(
                    account.closed
                      ? Strings.views.cartouches.account.buttons.open
                      : Strings.views.cartouches.account.buttons.close
                  )}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }

  private onConfigureClick(account: Account) {
    return openAccountViewPopup(account)
  }

  private onCloseClick(account: Account) {
    if (account.closed) {
      return this.openAccount(account)
    } else {
      return this.closeAccount(account)
    }
  }

  private closeAccount(account: Account) {
    return openCloseAccountPopin(account)
  }

  private openAccount(account: Account) {
    return openOpenAccountPopin(account)
  }

  private onClearClick(account: Account) {
    return openClearAllForAccountPopin(account)
  }

  private onReconcileClick(account: Account) {
    return openAccountReconcilePopup(account)
  }

  private getAccountTypeTranslation(type: AccountType) {
    if (AccountType.SAVINGS.equals(type))
      return tsl(Strings.resources.account.types.savings)
    else if (AccountType.DAILY_USAGE.equals(type))
      return tsl(Strings.resources.account.types.daily)
    else if (AccountType.INVESTMENT.equals(type))
      return tsl(Strings.resources.account.types.investment)
    else if (AccountType.CASH.equals(type))
      return tsl(Strings.resources.account.types.cash)
  }
}
