import React from "react"
import styles from "./HiddenToggle.module.scss"
import {ColorUtils, MaskedImage} from "@damntools.fr/react-utils"

export type HiddenToggleProps = {
  hidden: boolean
  onChange: (value: boolean) => void
  color?: string
  size?: string
}

const hiddenUrl =
  "https://icons.iconarchive.com/icons/aniket-suvarna/box-regular/48/bx-hide-icon.png"
const shownUrl =
  "https://icons.iconarchive.com/icons/aniket-suvarna/box-regular/48/bx-show-alt-icon.png"

export const HiddenToggle = ({
  hidden,
  onChange,
  color,
  size
}: HiddenToggleProps) => {
  return (
    <div className={styles.HiddenToggle} onClick={() => onChange(!hidden)}>
      <MaskedImage
        color={ColorUtils.colorToRGBA(color || "white")}
        icon={hidden ? hiddenUrl : shownUrl}
        title={hidden ? "Click to show" : "Click to hide"}
        width={size || "20px"}
      />
    </div>
  )
}
