import {ArrayList, List, toList} from "@damntools.fr/types"
import {
  Category,
  CategoryDto,
  CategoryDtoMapper,
  CreateCategoryDto,
  UpdateCategoryDto
} from "@damntools.fr/wnab-data"
import {AxiosService} from "../auth"
import {AxiosWrapper} from "@damntools.fr/http"

export class CategoryApiService {
  static INSTANCE: CategoryApiService | null = null
  private readonly axios: AxiosWrapper

  constructor() {
    this.axios = AxiosService.getAuthenticatedInstance().child({
      baseURL: "/resource/category"
    })
  }

  getAll(): Promise<List<Category>> {
    return this.axios
      .get("/")
      .then(res => new ArrayList<CategoryDto>(res.data))
      .then(res =>
        res
          .stream()
          .map(a => CategoryDtoMapper.get().mapTo(a))
          .collect(toList)
      )
  }

  create(category: CreateCategoryDto): Promise<CategoryDto> {
    return this.axios.post("/", category)
  }

  update(category: UpdateCategoryDto) {
    if (!category.id) return Promise.reject("Category should contains id ! ")
    return this.axios.put(`/${category.id}`, category)
  }

  delete(ids: List<number>) {
    return this.axios.delete(`/?ids=${ids.stream().join(",")}`).then(() => {})
  }

  moveTransactions(source: Category, target: Category): Promise<number> {
    return this.axios
      .put(`/tx/move/${source.id}/to/${target.id}`)
      .then(res => res.data as number)
  }

  static get(): CategoryApiService {
    if (this.INSTANCE === null) {
      this.INSTANCE = new CategoryApiService()
    }
    return this.INSTANCE
  }
}
